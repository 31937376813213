import { SxProps, Theme } from '@mui/material';
import { red, blue, grey } from '@mui/material/colors';
import { Scanner } from 'types';
import {
  FlowSumCategory,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  OptionsFeedColumnVisibility,
  PCFlowSumColors,
} from 'types/optionsFeed';

export const DEFAULT_TNS_FIELDS = Object.values(OptionsFeedColumnKey);

export const OPTIONS_FEED_MAX_LIVE_ROWS = 400;
export const OPTIONS_FEED_MAX_HIST_ROWS = 400;
export const OPTIONS_FEED_MAX_TOTAL_ROWS = 800;
export const OPTIONS_FEED_FETCH_BATCH_SIZE = 100; // Number of rows to fetch per batch

export const OPTIONS_FEED_WATCHLIST_FILTER_KEY = 'watchlist-syms-filter';

export const CONTRACT_DATA_COLUMN_KEYS = [
  OptionsFeedColumnKey.Underlying,
  OptionsFeedColumnKey.IsPut,
  OptionsFeedColumnKey.Strike,
  OptionsFeedColumnKey.Expiry,
  OptionsFeedColumnKey.DailyVolCumsum,
  OptionsFeedColumnKey.Premium,
  OptionsFeedColumnKey.Delta,
  OptionsFeedColumnKey.Gamma,
  OptionsFeedColumnKey.Vega,
];

export const DEFAULT_TNS_COL_VISIBILITY = Object.values(
  OptionsFeedColumnKey,
).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as OptionsFeedColumnVisibility);

export const DEFAULT_TNS_CONTRACTS_COL_VISIBILITY =
  CONTRACT_DATA_COLUMN_KEYS.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as OptionsFeedColumnVisibility);

export const TNS_UNMODIFIABLE_FIELDS = [
  OptionsFeedColumnKey.Time,
  OptionsFeedColumnKey.Underlying,
];

export enum OF_DEFAULT_FILTER_ID {
  Symbols = 'symbols',
  MinExp = 'min_expiration',
  MaxExp = 'max_exp',
  Premium = 'premium',
  Aggressor = 'aggressor',
  Side = 'side',
  IsPut = 'is_put',
  MinStrike = 'min_strike',
  MaxStrike = 'max_strike',
  MinVol = 'min_vol',
  MaxVol = 'max_vol',
  MinSize = 'min_size',
  MaxSize = 'max_size',
  MinOptPrice = 'min_opt_price',
  MaxOptPrice = 'max_opt_price',
  MinSpotPrice = 'min_spot_price',
  MaxSpotPrice = 'max_spot_price',
  MinBid = 'min_bid',
  MaxBid = 'max_bid',
  MinAsk = 'min_ask',
  MaxAsk = 'max_ask',
  MinOI = 'min_oi',
  MaxOI = 'max_oi',
  MinDelta = 'min_delta',
  MaxDelta = 'max_delta',
  MinGamma = 'min_gamma',
  MaxGamma = 'max_gamma',
  MinVega = 'min_vega',
  MaxVega = 'max_vega',
  MinIV = 'min_iv',
  MaxIV = 'max_iv',
  MinDateTime = 'min_date_time',
  MaxDateTime = 'max_date_time',
  IsBlock = 'is_block',
  IsSpread = 'is_spread',
  IsSweep = 'is_sweep',
  IsCross = 'is_cross',
}

export enum OF_FILTER_CONJUNCTION_ID {
  SaleType = 'saleType',
}

const getColumnSize = (key: OptionsFeedColumnKey) => {
  switch (key) {
    case OptionsFeedColumnKey.Time:
      return 110;
    case OptionsFeedColumnKey.Underlying:
      return 110;
    case OptionsFeedColumnKey.TradeSide:
      return 90;
    case OptionsFeedColumnKey.Strike:
      return 60;
    case OptionsFeedColumnKey.Aggressor:
      return 70;
    case OptionsFeedColumnKey.Expiry:
      return 110;
    case OptionsFeedColumnKey.StockPrice:
      return 125;
    case OptionsFeedColumnKey.Bid:
    case OptionsFeedColumnKey.Ask:
    case OptionsFeedColumnKey.Size:
      return 90;
    case OptionsFeedColumnKey.Price:
      return 120;
    case OptionsFeedColumnKey.Premium:
      return 110;
    case OptionsFeedColumnKey.Flags:
      return 100;
    case OptionsFeedColumnKey.IVol:
      return 80;
    default:
      return 75;
  }
};

export const DEFAULT_TNS_COL_SIZES = Object.values(OptionsFeedColumnKey).reduce(
  (acc, key) => {
    acc[key] = getColumnSize(key);
    return acc;
  },
  {} as OptionsFeedColumnSizes,
);

export const DEFAULT_TNS_CONTRACT_COL_SIZES = CONTRACT_DATA_COLUMN_KEYS.reduce(
  (acc, key) => {
    acc[key] = getColumnSize(key);
    return acc;
  },
  {} as OptionsFeedColumnSizes,
);

export enum OptionsFeedFilterTab {
  NewFilter = 'New Filter',
  SavedFilters = 'Saved Filters',
}

export enum OptionsFeedDataGridTab {
  FlowData = 'Flow Data',
  ContractData = 'Contract Data',
}

export const DEFAULT_PANEL_STYLES: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  paddingTop: '6px',
  paddingX: '16px',
  paddingBottom: '24px',
  gap: '16px',
  borderRadius: '8px',
  minWidth: 330,
  maxWidth: 330,
  height: '100%',
};

export const DEFAULT_TNS_SYM = '*';
export const DEFAULT_TNS_SYMBOLS = [DEFAULT_TNS_SYM];

export const OF_SCANNERS = [
  {
    label: 'VRP',
    value: Scanner.VOL_RISK_PREMIUM,
  },
  {
    label: 'Squeeze',
    value: Scanner.SQUEEZE,
  },
];

// expected categories
export const FLOW_SUM_CATEGORIES: FlowSumCategory[] = [
  'volume',
  'premium',
  'delta',
  'gamma',
  'vega',
];

export const DEFAULT_FLOW_SUM_COLORS: PCFlowSumColors = {
  put: red[500],
  call: blue[500],
  total: grey[500],
};
