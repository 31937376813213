import { STREAM_HOST_URL } from 'config/shared';
import useLog from 'hooks/useLog';
import { Filter, TnsFlowHighlights, TnsFlowSummary } from 'types/optionsFeed';
import { getFiltersForPayload, makeTnsFlowSummary } from 'util/optionsFeed';
import { encodeURIJson } from 'util/shared';

const useTnsFeed = () => {
  const { fetchAPIWithLog } = useLog('tnsFeed');

  /**
   * Fetches the flow summary from the API and transforms it to include the 'total' field.
   * @param filters - Array of filters to apply to the API request.
   * @returns A promise that resolves to TnsFlowSummary.
   */
  const fetchFlowSummary = async (
    filters: Filter[],
  ): Promise<TnsFlowSummary> => {
    try {
      const response = await fetchAPIWithLog(
        `sg/tns_flow_sum?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}`,
        undefined,
        STREAM_HOST_URL,
      );
      return makeTnsFlowSummary(response);
    } catch (err) {
      console.error(err);
      throw new Error(
        'Failed to fetch flow summary. Try again or contact us if the issue persists.',
      );
    }
  };

  /**
   * Fetches the flow highlights from the API
   * @returns A promise that resolves to TnsFlowHighlights.
   */
  const fetchFlowHighlights = async (): Promise<TnsFlowHighlights> => {
    try {
      const response = await fetchAPIWithLog(
        `sg/tns_highlights`,
        undefined,
        STREAM_HOST_URL,
      );

      return response?.json as TnsFlowHighlights;
    } catch (err) {
      console.error(err);
      throw new Error(
        'Failed to fetch flow highlights. Try again or contact us if the issue persists.',
      );
    }
  };

  return {
    fetchFlowSummary,
    fetchFlowHighlights,
  };
};

export default useTnsFeed;
