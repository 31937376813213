import { ALL_LENSES } from '../../config';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import {
  endQueryDateState,
  hiroETHState,
  productAccessState,
  timezoneState,
  workerState,
} from '../../states';
import { convertData } from '../../components/hiro/HiroChart/convertData';
import {
  calcOffsetMS,
  getHiroUrlParams,
  getLast,
  getQueryDateForSymbol,
  initHiroData,
  pruneLatest,
  rollingSums,
  toEntries,
  toKeyedEntry,
  update,
} from '../../util';
import poll from '../../util/poll';
import { HiroOptionType, HiroUpdatingType, ProductType } from '../../types';

const POLL_INTERVAL = 10_000; // 10s

function roll(latest: any, data: any, seconds: any) {
  return rollingSums(
    data.concat(latest),
    seconds,
    HiroUpdatingType.POLLING,
  ).slice(data.length);
}

export type useHiroPollingProps = {
  sym: string;
  seriesRefs: any;
  data: any;
  sigKey: any;
  rollingSeconds: any;
  priceSeriesRef: any;
  date: any;
  api: any;
};

const useHiroPolling = (enabled: boolean, props?: useHiroPollingProps) => {
  const {
    sym,
    seriesRefs,
    data,
    sigKey,
    rollingSeconds,
    priceSeriesRef,
    date,
    api,
  } = props ?? {};
  const latestData = useRef(initHiroData());
  const includeHiroETH = useRecoilValue(hiroETHState);
  const currentTimezone = useRecoilValue(timezoneState);
  const products = useRecoilValue(productAccessState);
  const worker = useRecoilValue(workerState);
  const endDate = useRecoilValue(endQueryDateState);

  let key, utcOffset, tke: any, allTotal, prices;
  if (enabled && props != null) {
    key = Object.keys(data).filter((k) => data[k].TOT.length > 0)[0];
    utcOffset = calcOffsetMS(currentTimezone, data[key].TOT[0].utc_time);
    tke = toKeyedEntry(sigKey, utcOffset);
    allTotal = data[key].TOT.concat((latestData.current as any)[key].TOT);
    prices = toEntries(allTotal, 'stock_price', currentTimezone);
  }

  const handleLatestResponse = useCallback(
    ({ json }: any) => {
      if (!enabled || json == null || json.length === 0 || sym == null) {
        return;
      }
      const fetchedData: any = convertData(
        json[sym],
        includeHiroETH,
        currentTimezone,
      );
      const lense2latest: any = latestData.current;

      // Update lenses
      const firstLense = ALL_LENSES[0];
      for (const lense of ALL_LENSES) {
        const fetched = fetchedData[lense];
        const lenseData = data[lense];
        const { TOT, C, P } = lense2latest[lense];
        const latestTotal = pruneLatest(
          getLast(lenseData.TOT),
          TOT,
          fetched.TOT,
        );
        const latestCalls = pruneLatest(getLast(lenseData.C), C, fetched.C);
        const latestPuts = pruneLatest(getLast(lenseData.P), P, fetched.P);
        const len = Math.max(
          ...[latestTotal, latestCalls, latestPuts].map((a) => a.length),
        );
        if (len <= 0) {
          console.debug('No new changes in latest HIRO signal');
          return;
        }
        const prevTotal = lenseData.TOT.concat(TOT);
        const refs = seriesRefs[lense];
        update(
          refs.TOT.current,
          roll(
            toEntries(latestTotal, sigKey, currentTimezone),
            toEntries(prevTotal, sigKey, currentTimezone),
            rollingSeconds,
          ),
        );
        update(
          refs.C.current,
          roll(
            toEntries(latestCalls, sigKey, currentTimezone),
            toEntries(lenseData.C.concat(C), sigKey, currentTimezone),
            rollingSeconds,
          ),
        );
        update(
          refs.P.current,
          roll(
            toEntries(latestPuts, sigKey, currentTimezone),
            toEntries(lenseData.P.concat(P), sigKey, currentTimezone),
            rollingSeconds,
          ),
        );

        // Update price with first lense data
        if (lense === firstLense) {
          const newPrices = toEntries(
            latestTotal,
            'stock_price',
            currentTimezone,
          );
          update(priceSeriesRef.current, newPrices);

          // If current time rolling is looking at latest data, update it's
          // rightmost bound to bring in new data
          const { from, to } = api.timeScale().getVisibleLogicalRange() ?? {};
          if (to >= lenseData.C.length + C.length) {
            api.timeScale().setVisibleLogicalRange({
              from: from === 0 ? 0 : from + len,
              to: to + len,
            });
          }
        }

        TOT.push(...latestTotal);
        C.push(...latestCalls);
        P.push(...latestPuts);
      }
    },
    // (seriesRefs is safe to access)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      api,
      currentTimezone,
      data,
      rollingSeconds,
      sigKey,
      sym,
      date,
      includeHiroETH,
      enabled,
    ],
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    latestData.current = initHiroData();
  }, [sym, enabled]);

  useEffect(() => {
    if (
      !enabled ||
      !endDate.isSame(
        getQueryDateForSymbol(sym, ProductType.HIRO, currentTimezone),
        'day',
      )
    ) {
      return;
    }

    const params = getHiroUrlParams({ syms: sym });
    return poll(worker, {
      url: products.includes(ProductType.HIRO)
        ? `v4/latestHiro?${params}`
        : `v1/free_latest_hiro?${params}`,
      interval: POLL_INTERVAL,
      onResponse: handleLatestResponse,
    });
  }, [
    worker,
    handleLatestResponse,
    sym,
    products,
    endDate,
    currentTimezone,
    enabled,
  ]);

  const getPollingSeriesData = (lense: string, optionType: HiroOptionType) => {
    const seriesData: any = latestData.current ?? {};
    return rollingSums(
      ((data ?? {})[lense]?.[optionType] ?? [])
        .concat(seriesData[lense]?.[optionType] ?? [])
        .map(tke),
      rollingSeconds,
      HiroUpdatingType.POLLING,
    );
  };

  return { getPollingSeriesData, prices };
};

export default useHiroPolling;
