import { Box, Stack } from '@mui/material';
import OptionsFeedDatagrid from './OptionsFeedDatagrid';
import useTnSWebsocket from 'hooks/optionsFeed/useTnSWebsocket';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { hiroSymbolsState, timezoneState } from 'states';
import { decode } from '@msgpack/msgpack';
import {
  OF_DEFAULT_FILTER_ID,
  OPTIONS_FEED_FETCH_BATCH_SIZE,
  OPTIONS_FEED_MAX_TOTAL_ROWS,
  OptionsFeedDataGridTab,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';
import { STREAM_HOST_URL } from 'config/shared';
import {
  addOrUpdateFilters,
  dedupeTnsRows,
  getFiltersForPayload,
  getFilterValue,
  getOptionFeedDataRow,
  getSortedOptionsFeedData,
} from 'util/optionsFeed';
import { dayjs, encodeURIJson, fetchRawAPI } from 'util/shared';
import { useLog } from 'hooks';
import {
  Filter,
  FilterConfig,
  FilterOperator,
  FilterPanelProps,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
  TnsSortedDataWindow,
} from 'types/optionsFeed';
import {
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridRowScrollEndParams,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { useOptionsFeedColumns } from './useOptionsFeedColumns';
import FiltersContainer from './filters/FiltersContainer';
import useEquities from 'hooks/equityhub/useEquities';
import {
  tnsDataGridActiveTabState,
  tnsEquityScannersDataState,
} from 'states/optionsFeed';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import useToast from 'hooks/useToast';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components/shared';
import DataGridFlowSummary from './summary/DataGridFlowSummary';
import useHomeContent from 'hooks/home/useHomeContent';
import { Earnings, HiroTimeRange } from 'types';
import ContractDataContainer from './ContractDataContainer';
import { debounce } from 'lodash';

interface OptionsFeedProps {
  filterPanelProps: FilterPanelProps;
  disabledColumnFilters?: OptionsFeedColumnKey[];
  disableWatchlistSelector?: boolean;
  tnsFlowLiveState: RecoilState<boolean>;
  activeCustomFilterState: RecoilState<FilterConfig | undefined>;
  newFilterItemsState: RecoilState<Filter[]>;
  columnSortModel: RecoilState<GridSortModel>;
  savedFiltersState: RecoilState<FilterConfig[]>;
  filterActiveTabState: RecoilState<OptionsFeedFilterTab>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  customGridSlotProps?: GridSlotsComponentsProps;
  isHiroView?: boolean;
}

const FLUSH_THRESHOLD = 0.9; // Flush when we reach 90% of max
const RETAIN_RATIO = 0.7; // Keep 70% of rows after flush

const OptionsFeed = ({
  disabledColumnFilters,
  filterPanelProps,
  activeCustomFilterState,
  savedFiltersState,
  newFilterItemsState,
  columnVisibilityState,
  columnOrderState,
  columnSortModel,
  columnSizingState,
  filterActiveTabState,
  tnsFlowLiveState,
  customGridSlotProps,
  isHiroView = false,
}: OptionsFeedProps) => {
  const { logError } = useLog('OptionsFeed');
  const { getEarningsForSyms } = useHomeContent();
  const { openToast } = useToast();
  const { getEquityScanners } = useEquities();

  const currentTimezone = useRecoilValue(timezoneState);
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const isTnsFlowLive = useRecoilValue(tnsFlowLiveState);
  const [activeGridTab, setActiveGridTab] = useRecoilState(
    tnsDataGridActiveTabState,
  );

  const sortModel = useRecoilValue(columnSortModel);
  const [eqScannersLoading, setEqScannersLoading] = useState<boolean>(false);
  const setEqScanners = useSetRecoilState(tnsEquityScannersDataState);

  const [histError, setHistError] = useState<string | null>(null);
  const [histDataLoading, setHistDataLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [histDataOffset, setHistDataOffset] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false); // To prevent multiple concurrent fetches

  const [combinedRows, setCombinedRows] = useState<RawOptionFeedData[]>([]);
  const [sortedDataWindow, setSortedDataWindow] = useState<
    TnsSortedDataWindow | undefined
  >(undefined);

  const [earningsList, setEarningsList] = useState<Earnings[]>([]);

  const [earningsLoading, setEarningsLoading] = useState<boolean>(false);

  const [activeCustomFilter, setActiveCustomFilter] = useRecoilState(
    activeCustomFilterState,
  );
  const [newFilters, setNewFilters] = useRecoilState(newFilterItemsState);

  const currentFilters = useMemo(() => {
    return activeCustomFilter?.value ?? newFilters;
  }, [activeCustomFilter, newFilters]);

  const setSavedFilters = useSetRecoilState(savedFiltersState);

  const { fetchSavedFilters } = useTnsFilters();

  const { columns, contractColumns } = useOptionsFeedColumns({
    disabledColumnFilters,
    earningsList,
  });

  useEffect(() => {
    if (filterPanelProps.currentSym != null) {
      setNewFilters((prev: Filter[]) => {
        return addOrUpdateFilters(prev, [
          {
            id: OF_DEFAULT_FILTER_ID.Symbols,
            value: [filterPanelProps.currentSym!],
            operator: FilterOperator.IsAnyOf,
            field: OptionsFeedColumnKey.Underlying,
          },
        ]);
      });
    }
  }, [filterPanelProps.currentSym]);

  // Handle live data updates
  const handleLiveDataUpdate = useCallback(
    (newRows: RawOptionFeedData[]) =>
      setCombinedRows((prevRows) =>
        getSortedOptionsFeedData(
          getLimitedRows(dedupeTnsRows(prevRows, newRows)),
          sortModel,
        ),
      ),
    [sortModel, setCombinedRows],
  );

  // data comes through this websocket
  const { error } = useTnSWebsocket(
    currentFilters,
    handleLiveDataUpdate,
    sortedDataWindow,
    isTnsFlowLive,
  );

  const getUpdatedFiltersWithTime = (
    prevFilters: Filter[],
    from: number,
    to: number,
    currentTZ: string,
  ) => {
    const now = dayjs().tz(currentTZ);

    const differInMins = (t1: number, t2: number, mins = 1) => {
      return Math.abs(t1 - t2) > mins * 60 * 1_000;
    };

    // Extract previous filter values
    const prevFromDateTime = getFilterValue<number | null>(
      prevFilters,
      OF_DEFAULT_FILTER_ID.MinDateTime,
      null,
    );

    const prevToTime = getFilterValue<number | null>(
      prevFilters,
      OF_DEFAULT_FILTER_ID.MaxDateTime,
      null,
    );

    // Determine if 'from' should be updated
    const shouldUpdateFrom = () => {
      if (from == null) {
        return false;
      }
      if (!prevFromDateTime) {
        return true;
      }
      return differInMins(from, prevFromDateTime, 1); // differ by 1 minute from previous from
    };

    // Determine if 'to' should be updated
    const shouldUpdateTo = () => {
      if (to == null) {
        return false;
      }
      return differInMins(to, now.valueOf(), 1) && to !== prevToTime; // differ by 1 minute from "now" and not match previously set 'to' time
    };

    const newFilters: Filter[] = [];

    // Update 'from' filter if necessary
    if (shouldUpdateFrom()) {
      newFilters.push({
        field: OptionsFeedColumnKey.Time,
        value: from,
        id: OF_DEFAULT_FILTER_ID.MinDateTime,
        operator: FilterOperator.GreaterThanOrEqual,
      });
    }

    // Update 'to' filter if necessary
    if (shouldUpdateTo()) {
      newFilters.push({
        field: OptionsFeedColumnKey.Time,
        value: to,
        id: OF_DEFAULT_FILTER_ID.MaxDateTime,
        operator: FilterOperator.LessThanOrEqual,
      });
    }

    if (newFilters.length > 0) {
      return addOrUpdateFilters(prevFilters, newFilters);
    }

    return prevFilters;
  };

  // Debounced function to update filters
  const debouncedUpdateFilters = useCallback(
    debounce(
      (
        hiroTimeRange: HiroTimeRange | undefined,
        currentTZ: string,
        isCustomFilterUpdate = false,
      ) => {
        if (hiroTimeRange != null) {
          const { from, to } = hiroTimeRange;

          if (isCustomFilterUpdate) {
            setActiveCustomFilter((prev) => {
              return {
                ...prev,
                value: getUpdatedFiltersWithTime(
                  prev?.value ?? [],
                  from,
                  to,
                  currentTZ,
                ),
              } as FilterConfig;
            });
          } else {
            setNewFilters((prev: Filter[]) =>
              getUpdatedFiltersWithTime(prev, from, to, currentTZ),
            );
          }
        }
      },
      300, // 300ms debounce delay
    ),
    [],
  );

  useEffect(() => {
    // TODO: filters are persisted in localStorage, so old time range remains after page refresh. Reset on refresh.
    debouncedUpdateFilters(filterPanelProps.hiroTimeRange, currentTimezone);

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedUpdateFilters.cancel();
    };
  }, [filterPanelProps.hiroTimeRange, currentTimezone, debouncedUpdateFilters]);

  const getLimitedRows = (newRows: RawOptionFeedData[]) => {
    if (newRows.length > OPTIONS_FEED_MAX_TOTAL_ROWS * FLUSH_THRESHOLD) {
      const keepCount = Math.floor(OPTIONS_FEED_MAX_TOTAL_ROWS * RETAIN_RATIO);
      return newRows.slice(0, keepCount);
    }
    return newRows;
  };

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: FilterConfig[] = await fetchSavedFilters(false); // fetch only noSym:false filters
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10_000,
        });
      }
    }
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchEquityScanners() {
      try {
        setEqScannersLoading(true);
        const sc = await getEquityScanners(); // Fetch all equity scanners data
        setEqScanners(sc);
      } catch (err) {
        console.error(err);
        openToast({
          message:
            'Something went wrong while fetching equity scanners data. Refresh the page to retry or contact us if the issue persists.',
          type: 'error',
          duration: 10_000,
        });
      } finally {
        setEqScannersLoading(false);
      }
    }
    fetchEquityScanners();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setEarningsLoading(true);

      try {
        const earnings = await getEarningsForSyms([...hiroSyms]);
        setEarningsList(earnings);
      } catch (err) {
        logError(err, 'fetch earnings list data');
      } finally {
        setEarningsLoading(false);
      }
    };

    fetchData();
  }, [hiroSyms]);

  const getTnsFeed = async (
    filters: Filter[],
    sorting: GridSortModel,
    offset: number = 0,
    size: number = OPTIONS_FEED_FETCH_BATCH_SIZE,
  ): Promise<RawOptionFeedData[]> => {
    let result: RawOptionFeedData[] = [];
    try {
      setHistError(null);
      setHistDataLoading(true);

      const response = await fetchRawAPI(
        `sg/tns_feed?filters=${encodeURIJson(
          getFiltersForPayload(filters),
        )}&sorting=${encodeURIJson(sorting)}&offset=${offset}&limit=${size}`,
        undefined,
        STREAM_HOST_URL,
      );

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const histData: any = decode(arrayBuffer);

      result = histData.map((d: any[]) => getOptionFeedDataRow(d));

      setHasMore(result.length >= size);
    } catch (err) {
      logError(err);
      setHistError('Something went wrong while fetching data...');
    } finally {
      setHistDataLoading(false);
      setIsFetching(false);
    }
    return result;
  };

  useEffect(() => {
    const fetchData = async (filters: Filter[], sorting: GridSortModel) => {
      setIsFetching(true);
      const tnsFeedData = await getTnsFeed(filters, sorting);
      setHistDataOffset(tnsFeedData.length);
      setCombinedRows(tnsFeedData);
      setSortedDataWindow({
        sortModel: sorting ?? [],
        firstRow: tnsFeedData[0],
        lastRow: tnsFeedData[tnsFeedData.length - 1],
      });
    };

    // re-fetch data when filters or sorting changes
    fetchData(currentFilters, sortModel);
  }, [currentFilters, sortModel]);

  const handleOnRowsScrollEnd = useCallback<
    NonNullable<DataGridPremiumProps['onRowsScrollEnd']>
  >(
    async (_params: GridRowScrollEndParams) => {
      if (isFetching || !hasMore) {
        return; // Prevent multiple fetches or fetching when no more data
      }

      setIsFetching(true);
      const tnsFeedData = await getTnsFeed(
        currentFilters,
        sortModel,
        histDataOffset,
      );

      const newCombinedRows = getLimitedRows(
        getSortedOptionsFeedData(
          dedupeTnsRows(combinedRows, tnsFeedData),
          sortModel,
        ),
      );

      setCombinedRows(newCombinedRows);
      setSortedDataWindow((prev) => ({
        sortModel: prev?.sortModel ?? [],
        firstRow: newCombinedRows[0],
        lastRow: newCombinedRows[newCombinedRows.length - 1],
      }));
      setHistDataOffset((prevOffset) => prevOffset + tnsFeedData.length);
    },
    [
      currentFilters,
      combinedRows,
      sortModel,
      histDataOffset,
      isFetching,
      hasMore,
    ],
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        display: 'flex',
        gap: '8px',
      }}
    >
      <FiltersContainer
        activeCustomFilterState={activeCustomFilterState}
        activeTabState={filterActiveTabState}
        newFilterItemsState={newFilterItemsState}
        savedFiltersState={savedFiltersState}
        filterPanelProps={filterPanelProps}
      />

      <Stack
        sx={{
          backgroundColor: 'background.paper',
          paddingTop: '6px',
          paddingX: '16px',
          paddingBottom: '24px',
          gap: '12px',
          borderRadius: '8px',
          display: 'flex',
          overflow: 'hidden',
          flexGrow: 1,
          maxWidth: '100%',
        }}
      >
        {isHiroView ? (
          <OptionsFeedDatagrid
            rows={combinedRows}
            columns={columns}
            filterPanelOpenState={filterPanelProps.openState}
            flowLiveState={tnsFlowLiveState}
            columnSortModelState={columnSortModel}
            columnVisibilityState={columnVisibilityState}
            columnOrderState={columnOrderState}
            columnSizingState={columnSizingState}
            customGridSlotProps={customGridSlotProps}
            isError={error != null || histError != null}
            isLoading={earningsLoading || histDataLoading || eqScannersLoading}
            onRowsScrollEnd={handleOnRowsScrollEnd}
          />
        ) : (
          <TabContext value={activeGridTab}>
            <DataGridFlowSummary filters={currentFilters} />
            <Tabs
              options={
                new Map(
                  Object.values(OptionsFeedDataGridTab).map((t) => [t, t]),
                )
              }
              onChange={(_evt, newTab: OptionsFeedDataGridTab) =>
                setActiveGridTab(newTab)
              }
              isFullWidth
              tabButtonSx={{ minHeight: 40 }}
              tabListSx={{ minHeight: 40 }}
            />
            <TabPanel
              value={OptionsFeedDataGridTab.FlowData}
              sx={{
                padding: 0,
                overflow: 'hidden',
              }}
            >
              <TabPanelWrapper>
                <OptionsFeedDatagrid
                  rows={combinedRows}
                  columns={columns}
                  columnSortModelState={columnSortModel}
                  filterPanelOpenState={filterPanelProps.openState}
                  flowLiveState={tnsFlowLiveState}
                  columnVisibilityState={columnVisibilityState}
                  columnOrderState={columnOrderState}
                  columnSizingState={columnSizingState}
                  customGridSlotProps={customGridSlotProps}
                  isError={error != null || histError != null}
                  isLoading={
                    earningsLoading || histDataLoading || eqScannersLoading
                  }
                  onRowsScrollEnd={handleOnRowsScrollEnd}
                />
              </TabPanelWrapper>
            </TabPanel>
            <TabPanel
              value={OptionsFeedDataGridTab.ContractData}
              sx={{
                padding: 0,
                overflow: 'hidden',
              }}
            >
              <TabPanelWrapper>
                <ContractDataContainer
                  columns={contractColumns}
                  filters={currentFilters}
                  filterPanelOpenState={filterPanelProps.openState}
                />
              </TabPanelWrapper>
            </TabPanel>
          </TabContext>
        )}
      </Stack>
    </Box>
  );
};

const TabPanelWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      overflow: 'hidden',
      height: '100%',
      maxHeight: '100%',
      width: '100%',
    }}
  >
    {children}
  </Box>
);

export default OptionsFeed;
