import useLog from 'hooks/useLog';
import { Filter, FilterConfig } from 'types/optionsFeed';
import { getFilter } from 'util/optionsFeed';

const useTnsFilters = () => {
  const { fetchAPIWithLog } = useLog('tnsFilters');

  const fetchSavedFilters = async (
    noSym?: boolean,
  ): Promise<FilterConfig[]> => {
    const response = await fetchAPIWithLog(
      `v1/me/tnsFilters${noSym != null ? `?no_sym=${noSym}` : ''}`,
    );

    if (response.error) {
      throw new Error(
        'Failed to fetch your filters. Try again or contact us if the issue persists.',
      );
    }

    return response.map(getFilter);
  };

  const createNewSavedFilter = async (
    name: string,
    filters: Filter[],
    noSym: boolean,
  ): Promise<FilterConfig> => {
    const response = await fetchAPIWithLog(`v1/me/tnsFilters`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        filter: filters,
        noSym,
      }),
    });

    if (response.error) {
      throw new Error(
        'Failed to create your new filter. Try again or contact us if the issue persists.',
      );
    }

    return response.data[0] && getFilter(response.data[0]);
  };

  const editSavedFilter = async (
    filter: FilterConfig,
  ): Promise<FilterConfig> => {
    const response = await fetchAPIWithLog(`v1/me/tnsFilters/${filter.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: filter.name,
        filter: filter.value,
      }),
    });

    if (response.error) {
      throw new Error(
        'Failed to update your saved filter. Try again or contact us if the issue persists.',
      );
    }

    return response.data[0] && getFilter(response.data[0]);
  };

  const deleteFilter = async (filterId: FilterConfig['id']): Promise<void> => {
    const response = await fetchAPIWithLog(`v1/me/tnsFilters/${filterId}`, {
      method: 'DELETE',
    });

    if (response.error) {
      throw new Error(
        'Failed to delete. Try again or contact us if the issue persists.',
      );
    }
  };

  return {
    fetchSavedFilters,
    createNewSavedFilter,
    editSavedFilter,
    deleteFilter,
  };
};

export default useTnsFilters;
