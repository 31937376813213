import { atom, atomFamily, selector } from 'recoil';
import {
  HiroTimeseries,
  LinkGroupId,
  PriceData,
  ProductType,
  RawSpotgammaLevels,
  SearchHandlerFunction,
  SuggestionSearchData,
  Tick,
  ToastData,
} from '../types';
import {
  getDateFormatted,
  getQueryDate,
  getQueryDateFormatted,
  initHiroData,
  isBloomberg,
  isMarketOpen,
  prevBusinessDay,
} from '../util';
import PollingWorker from 'PollingWorker';
import {
  INDICES_NAMES,
  MAIN_CONTENT_DEFAULT_WIDTH,
  MOBILE_MAX_WIDTH,
} from '../config';
import { recoilPersist } from 'recoil-persist';
import { colorModeState, productAccessState, userSettingsState } from './auth';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_OPEN } from '../components/core';
import { Theme } from '@mui/material';
import { themeForMode } from '../theme';

const { persistAtom } = recoilPersist();

export const selectedHiroData = atom<HiroTimeseries>({
  key: 'shared-selectedHiroData',
  default: initHiroData(),
});

export const realTimeInitialDataState = atom<Tick[]>({
  key: 'realTimeInitialDataState',
  default: [],
});

export const queryDateState = atom<string>({
  key: 'shared-queryDateState',
  default: getQueryDateFormatted(),
});

export const spotgammaDataState = atom<RawSpotgammaLevels | undefined>({
  key: 'shared-spotgammaDataState',
  default: undefined,
});

export const activeSearchState = atom<string>({
  key: 'shared-activeSearchState',
  default: '',
});

export const sidebarExpandedState = atom<boolean>({
  key: 'shared-sidebarExpanded',
  default: true,
});

export const drawerExpandedState = atom<boolean>({
  key: 'shared-drawerExpanded',
  default: true,
});

export const bottomDrawerExpandedState = atom<boolean>({
  key: 'shared-bottomDrawerExpandedState',
  default: false,
});

export const workerState = atom<PollingWorker>({
  key: 'shared-workerState',
  default: undefined,
});

export const autoRefreshState = atom<NodeJS.Timeout | undefined>({
  key: 'shared-autoRefreshState',
  default: undefined,
});

export const searchSuggestionsState = atom<{
  text: string;
  suggestions: SuggestionSearchData[];
}>({
  key: 'shared-searchSuggestionsState',
  default: {
    text: '',
    suggestions: [],
  },
});

export const searchSuggestionsData = atom<SuggestionSearchData[]>({
  key: 'shared-searchSuggestionsData',
  default: INDICES_NAMES,
});

export const searchHandlerState = atom<SearchHandlerFunction>({
  key: 'shared-searchHandlerState',
  default: (_value: string) => {},
});

export const onSymSearchChangeState = atom<((arg: string) => void) | undefined>(
  {
    key: 'shared-onSymSearchChangeState',
    default: undefined,
  },
);

export const isMobileState = atom<boolean>({
  key: 'shared-isMobileState',
  default: window.innerWidth <= MOBILE_MAX_WIDTH,
});

export const isSideDrawerExpandedState = atom<boolean>({
  key: 'shared-isSideDrawerExpandedState',
  default: !isBloomberg() && window.innerWidth > MOBILE_MAX_WIDTH,
  effects_UNSTABLE: [persistAtom],
});

export const expandedLinkGroupIdState = atom<LinkGroupId | undefined>({
  key: 'shared-expandedLinkGroupIdState',
  default: undefined,
});

export const screenWidthState = atom<number>({
  key: 'shared-screenWidthState',
  default: window.innerWidth,
});

export const screenWidthWithoutSidebarState = selector<number>({
  key: 'shared-screenWidthWithoutSidebarState',
  get: ({ get }) => {
    const isMobile = get(isMobileState);
    const screenWidth = get(screenWidthState);
    if (isMobile) {
      return screenWidth;
    }

    const sidebarOpen = get(isSideDrawerExpandedState);
    return screenWidth - (sidebarOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH);
  },
});

export const screenHeightState = atom<number>({
  key: 'shared-screenHeightState',
  default: window.innerHeight,
});

export const currentToastState = atom<ToastData | undefined>({
  key: 'shared-currentToastState',
  default: undefined,
});

export const componentIsExpandedState = atomFamily<boolean, string>({
  key: 'shared-componentIsExpandedState',
  default: false,
});

export const mainContentWidthRatioState = atom<number>({
  key: 'shared-mainContentWidthRatioState',
  default: MAIN_CONTENT_DEFAULT_WIDTH,
});

export const mainContentWidthState = selector<number>({
  key: 'shared-mainContentWidthState',
  get: ({ get }) => {
    const ratio = get(mainContentWidthRatioState);
    const screenWidth = get(screenWidthState);
    return ratio * screenWidth;
  },
});

export const windowIsVisibleState = atom<boolean>({
  key: 'shared-windowIsVisibleState',
  default: !document.hidden,
});

export const currentProductTypeState = atom<ProductType | null>({
  key: 'shared-currentProductTypeState',
  default: null,
});

export const hasAccessToProductState = selector<boolean>({
  key: 'shared-hasAccessToProductState',
  get: ({ get }) => {
    const product = get(currentProductTypeState);
    if (product == null) {
      return true;
    }

    const productsWithAccess = get(productAccessState);
    return productsWithAccess.includes(product);
  },
});

export const todaysOpenArrState = atom<PriceData[] | undefined>({
  key: 'shared-todaysOpenArrState',
  default: undefined,
});

export const isMarketOpenState = atom<boolean>({
  key: 'shared-isMarketOpenState',
  default: isMarketOpen(),
});

// sometimes we need to get the theme in places where we cant use the useTheme method
// the below allows us to do that
export const themeState = selector<Theme>({
  key: 'shared-themeState',
  get: ({ get }) => {
    const colorMode = get(colorModeState);
    return themeForMode(colorMode);
  },
});
