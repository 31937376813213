import { useCallback } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  equitiesFetchLoadingState,
  productAccessState,
  unpricedEquitiesState,
  workerState,
} from 'states';
import { Equity, ProductType } from '../../types';
import { ONE_HOUR_MS, prevBusinessDay } from '../../util';
import useLog from '../useLog';
import poll from '../../util/poll';

const POLL_INTERVAL = 4 * ONE_HOUR_MS;

const useEquities = () => {
  const products = useRecoilValue(productAccessState);
  const worker = useRecoilValue(workerState);
  const setUnpricedEquities = useSetRecoilState(unpricedEquitiesState);
  const setEquitiesLoading = useSetRecoilState(equitiesFetchLoadingState);

  const { fetchAPIWithLog } = useLog('useEquities');

  const convertDataToMap = (data: any) => {
    const newEquities: Map<string, Equity> = new Map(
      data.map((e: Equity) => [e.sym, e]),
    );
    return newEquities;
  };

  const getEquitiesUrl = useCallback(() => {
    const date = prevBusinessDay().format('YYYY-MM-DD');
    const url = products.includes(ProductType.EQUITYHUB)
      ? 'v4/equities'
      : 'v1/free_equities';
    return `${url}?date=${date}`;
  }, [products]);

  // Outputs an object with keys being 'sym' and value being the 'name' of equity
  const getSymbols = useCallback(async () => {
    return await fetchAPIWithLog(`v1/eh_symbols`);
  }, []);

  const getEquityScanners = useCallback(async () => {
    const url = products.includes(ProductType.EQUITYHUB)
      ? 'v1/equityScanners'
      : 'v1/free_equityScanners';
    return await fetchAPIWithLog(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const handlePollResponse = ({ json }: any) => {
    if ((json?.length ?? 0) == 0) {
      return;
    }
    setUnpricedEquities(convertDataToMap(json));
    setEquitiesLoading(false);
  };

  const setupEquitiesPoller = useCallback(() => {
    return poll(worker, {
      url: getEquitiesUrl(),
      interval: POLL_INTERVAL,
      onResponse: handlePollResponse,
      maxRetries: 1,
    });
  }, [worker, getEquitiesUrl]);

  return {
    getSymbols,
    getEquityScanners,
    setupEquitiesPoller,
  };
};

export default useEquities;
