import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { timezoneState } from 'states';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { dayjs, getDefaultSlotProps } from 'util/shared';
import { Dayjs } from 'dayjs';

interface DateTimeSelectorProps {
  value: number | null; // Single timestamp in milliseconds (number)
  onChange: (newValue: number | null) => void;
  label: string;
  min?: number; // Minimum allowed timestamp
  max?: number; // Maximum allowed timestamp
  disabled?: boolean;
}

const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  value,
  onChange,
  label,
  min,
  max,
  disabled,
}) => {
  const theme = useTheme();
  const userTimezone = useRecoilValue(timezoneState);

  // Convert timestamp to Dayjs in UTC, then display in user's timezone
  const selectedDate = value ? dayjs.utc(value).tz(userTimezone) : null;

  const minDate = min ? dayjs.utc(min).tz(userTimezone) : undefined;
  const maxDate = max ? dayjs.utc(max).tz(userTimezone) : undefined;

  // Handle the date change, convert back to UTC for backend
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate?.isValid()) {
      const updatedDate = dayjs(newDate).utc().valueOf();
      onChange(updatedDate);
    } else {
      onChange(null);
    }
  };

  const defaultSlotProps = getDefaultSlotProps(theme);

  return (
    <Box sx={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          minDateTime={minDate}
          maxDateTime={maxDate}
          slotProps={{
            ...defaultSlotProps,
            textField: {
              ...defaultSlotProps.textField,
              style: {
                width: '100%',
                fontSize: '14px',
              },
            },
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimeSelector;
