import {
  GridSortItem,
  gridStringOrNumberComparator,
  gridNumberComparator,
  gridDateComparator,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import {
  FLOW_SUM_CATEGORIES,
  OF_DEFAULT_FILTER_ID,
  OF_FILTER_CONJUNCTION_ID,
} from 'config/optionsFeed';
import {
  OPTION_FEED_IDX,
  OptionFlag,
  OptionsFeedColumnKey,
  OptionTransactionType,
  OptionType,
  RawOptionFeedData,
  OptionTradeSide,
  OptionSaleType,
  Filter,
  Aggressor,
  FilterConfig,
  FilterOperator,
  TransactionSentiment,
  ConjunctionOp,
  FilterItem,
  Conjunction,
  TnsFlowSummary,
  TnsSortedDataWindow,
  RawOptionFeedContract,
} from 'types/optionsFeed';
import { v4 as uuidv4 } from 'uuid';
import { ONE_DAY_MS } from './shared/common';

const Mask = {
  OPTION_TYPE: 0x1, // 1 << 0
  TNS_TYPE: 0x6, // 3 << 1
  SIDE_TYPE: 0x18, // 3 << 3
  IS_NEXT_EXP: 0x20, // 1 << 5
  IS_RETAIL: 0x40, // 1 << 6
  IS_BLOCK: 0x80, // 1 << 7
  IS_SPREAD: 0x100, // 1 << 8
  IS_SWEEP: 0x200, // 1 << 9
  IS_CROSS: 0x800, // 1 << 11
  FILTERED_MASK: 0x4000, // 1 << 14
};

const TYPE_STRS = [
  OptionTransactionType.NEW, // new
  OptionTransactionType.CORRECTION, // correction
  OptionTransactionType.CANCEL, // cancel
];

const typeToString = (tns_type: number): OptionTransactionType =>
  TYPE_STRS[tns_type];

const SIDE_STRS = [
  Aggressor.UNDETERMINED, // undetermined
  Aggressor.BUY, // buy
  Aggressor.SELL, // sell
];

const sideToString = (side_idx: number): Aggressor => SIDE_STRS[side_idx];

export const parseOptionFlags = (flags: number): OptionFlag => ({
  type: flags & Mask.OPTION_TYPE ? OptionType.PUT : OptionType.CALL,
  transactionType: typeToString((flags & Mask.TNS_TYPE) >> 1),
  sideType: sideToString((flags & Mask.SIDE_TYPE) >> 3),
  isNextExp: !!(flags & Mask.IS_NEXT_EXP) ? true : false,
  isRetail: !!(flags & Mask.IS_RETAIL) ? true : false,
  isBlock: !!(flags & Mask.IS_BLOCK) ? true : false,
  isSpread: !!(flags & Mask.IS_SPREAD) ? true : false,
  isSweep: !!(flags & Mask.IS_SWEEP) ? true : false,
  isCross: !!(flags & Mask.IS_CROSS) ? true : false,
});

// rawSignal format: ['AAPL', 1718733518879n, 6553.737343480935, 228.74070754478853, 12244.064045890529, 213.61, 7381904254035821727n, 1737147600000n, 200, 1, 273, 'f', 7, 6.95, 7.05, 0.2009438256563837]
export const getOptionFeedDataRow = (rawSignal: any[]): RawOptionFeedData => {
  const row: RawOptionFeedData = rawSignal.reduce((acc, value, index) => {
    switch (index) {
      case OPTION_FEED_IDX.SYM:
        acc[OptionsFeedColumnKey.Underlying] = value.replace(/^.*\|/, '');
        break;
      case OPTION_FEED_IDX.TIME:
        acc[OptionsFeedColumnKey.Time] = BigInt(value);
        break;
      case OPTION_FEED_IDX.DELTA:
        acc[OptionsFeedColumnKey.Delta] = value;
        break;
      case OPTION_FEED_IDX.GAMMA:
        acc[OptionsFeedColumnKey.Gamma] = value;
        break;
      case OPTION_FEED_IDX.VEGA:
        acc[OptionsFeedColumnKey.Vega] = value;
        break;
      case OPTION_FEED_IDX.STOCK_PRICE:
        acc[OptionsFeedColumnKey.StockPrice] = value;
        break;
      case OPTION_FEED_IDX.TNS_INDEX:
        acc[OptionsFeedColumnKey.TnsIndex] = value;
        break;
      case OPTION_FEED_IDX.EXPIRATION:
        acc[OptionsFeedColumnKey.Expiry] = BigInt(value);
        break;
      case OPTION_FEED_IDX.STRIKE:
        acc[OptionsFeedColumnKey.Strike] = value;
        break;
      case OPTION_FEED_IDX.SIZE:
        acc[OptionsFeedColumnKey.Size] = value;
        break;
      case OPTION_FEED_IDX.FLAG:
        const flag = parseOptionFlags(value);
        acc[OptionsFeedColumnKey.Flags] = parseOptionFlags(value);
        acc[OptionsFeedColumnKey.IsBlock] = flag.isBlock;
        acc[OptionsFeedColumnKey.IsSpread] = flag.isSpread;
        acc[OptionsFeedColumnKey.IsSweep] = flag.isSweep;
        acc[OptionsFeedColumnKey.IsCross] = flag.isCross;
        acc[OptionsFeedColumnKey.IsPut] =
          flag.type === OptionType.PUT ? true : false;
        acc[OptionsFeedColumnKey.Aggressor] = flag.sideType;
        break;
      case OPTION_FEED_IDX.EXCHANGE_SALE_CONDITIONS:
        acc[OptionsFeedColumnKey.ExchangeSaleCondition] = value;
        break;
      case OPTION_FEED_IDX.PRICE:
        acc[OptionsFeedColumnKey.Price] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.BID:
        acc[OptionsFeedColumnKey.Bid] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.ASK:
        acc[OptionsFeedColumnKey.Ask] = isNaN(value) ? null : value;
        break;
      case OPTION_FEED_IDX.IVOL:
        acc[OptionsFeedColumnKey.IVol] = value;
        break;
      case OPTION_FEED_IDX.PREVOI:
        acc[OptionsFeedColumnKey.PrevOi] = value;
        break;
      case OPTION_FEED_IDX.DAILYVOL:
        acc[OptionsFeedColumnKey.DailyVolCumsum] = value;
        break;
      default:
        break;
    }
    return acc;
  }, {} as RawOptionFeedData);

  // dynamic field values
  row[OptionsFeedColumnKey.Premium] =
    row[OptionsFeedColumnKey.Size] * row[OptionsFeedColumnKey.Price] * 100;

  row[OptionsFeedColumnKey.TradeSide] = getOptionTradeSide(row);

  row.id = `${row[OptionsFeedColumnKey.Underlying]}-${
    row[OptionsFeedColumnKey.Strike]
  }-${row[OptionsFeedColumnKey.Flags].type}-${
    row[OptionsFeedColumnKey.Expiry]
  }-${row[OptionsFeedColumnKey.TnsIndex]}}-${uuidv4()}`;

  return row;
};

export const satisfiesDefaultConditions = (row: RawOptionFeedData) =>
  row[OptionsFeedColumnKey.Premium] > 1000;

export const getOptionTradeSide = (row: RawOptionFeedData) => {
  const optPrice = row[OptionsFeedColumnKey.Price];
  const bidPrice = row[OptionsFeedColumnKey.Bid];
  const askPrice = row[OptionsFeedColumnKey.Ask];

  if (optPrice < bidPrice) {
    return OptionTradeSide.BB;
  } else if (optPrice === bidPrice) {
    return OptionTradeSide.B;
  } else if (optPrice > bidPrice && optPrice < askPrice) {
    return OptionTradeSide.M;
  } else if (optPrice === askPrice) {
    return OptionTradeSide.A;
  }
  return OptionTradeSide.AA;
};

export const addOrUpdateFilters = (
  filters: Filter[],
  newFilters: Filter[],
): Filter[] => {
  const filterMap = new Map<string, Filter>(filters.map((f) => [f.id, f]));

  // Add or update new filters in the map
  newFilters.forEach((newFilter: Filter) => {
    const { id } = newFilter;

    if (isConjunction(newFilter)) {
      // If it's a conjunction, add or remove based on the number of filters it contains
      newFilter.filters.length > 0
        ? filterMap.set(id, newFilter)
        : filterMap.delete(id);
    } else {
      // It's a FilterItem; determine if it should be added or removed
      const { value } = newFilter;

      if (
        (Array.isArray(value) && value.length === 0) ||
        value === '' ||
        value == null
      ) {
        filterMap.delete(id);
      } else {
        filterMap.set(id, newFilter);
      }
    }
  });

  // Convert map back to array
  return Array.from(filterMap.values());
};

export const getFilter = (filterObj: any): FilterConfig => ({
  id: filterObj.filter_id,
  value: filterObj.filter,
  name: filterObj.name,
  noSym: filterObj.no_sym,
});

export const satisfyAllFilters = (
  data: RawOptionFeedData,
  filters: Filter[],
): boolean => filters.every((f) => satisfyFilter(data, f));

export const satisfyConjunction = (
  data: RawOptionFeedData,
  conjunction: Conjunction,
): boolean =>
  conjunction.op === ConjunctionOp.And
    ? conjunction.filters.every((f) => satisfyFilter(data, f))
    : conjunction.filters.some((f) => satisfyFilter(data, f));

export const satisfyFilter = (
  data: RawOptionFeedData,
  filter: Filter,
): boolean =>
  isConjunction(filter)
    ? satisfyConjunction(data, filter)
    : satisfyFilterItem(data, filter);

export const satisfyFilterItem = (
  data: RawOptionFeedData,
  filterItem: FilterItem,
): boolean => {
  const { field, value, operator } = filterItem;

  const dataValue = data[field as keyof RawOptionFeedData];

  // Handle Expiration field specifically
  if (field === OptionsFeedColumnKey.Expiry) {
    // Convert the number of days to a BigInt timestamp for comparison
    const filterValue = daysToBigInt(value as number);
    return NUMERIC_COMPARISONS.get(operator)?.(
      dataValue as bigint,
      filterValue,
    )!;
  }

  // Convert dataValue and filter value to the same type before comparison
  const isDataValueBigInt = typeof dataValue === 'bigint';
  const isFilterValueBigInt = typeof value === 'bigint';

  if (isDataValueBigInt && !isFilterValueBigInt) {
    // Convert filter value to BigInt for comparison
    const bigIntFilterValue = BigInt(value as number);
    return NUMERIC_COMPARISONS.get(operator)?.(
      dataValue as bigint,
      bigIntFilterValue,
    )!;
  }

  if (!isDataValueBigInt && isFilterValueBigInt) {
    // Convert data value to number for comparison
    const numberDataValue = Number(dataValue);
    return NUMERIC_COMPARISONS.get(operator)?.(
      numberDataValue,
      value as bigint,
    )!;
  }

  // Normal numeric or string comparisons
  if (
    typeof dataValue !== 'number' &&
    typeof dataValue !== 'string' &&
    typeof dataValue !== 'boolean'
  ) {
    return false;
  }

  if (
    (typeof dataValue === 'string' || typeof dataValue === 'boolean') &&
    dataValue === ''
  ) {
    return true;
  }

  if (typeof dataValue === 'number' || typeof dataValue === 'bigint') {
    return NUMERIC_COMPARISONS.get(operator)?.(
      dataValue,
      value as number | bigint,
    )!;
  }

  return handleEqualityAndArrayComparison(operator, dataValue, value);
};

const NUMERIC_COMPARISONS = new Map<
  FilterOperator,
  (a: bigint | number, b: bigint | number) => boolean
>([
  [FilterOperator.LessThan, (a, b) => a < b],
  [FilterOperator.LessThanOrEqual, (a, b) => a <= b],
  [FilterOperator.GreaterThan, (a, b) => a > b],
  [FilterOperator.GreaterThanOrEqual, (a, b) => a >= b],
]);

const handleEqualityAndArrayComparison = (
  operator: FilterOperator,
  dataValue: string | boolean | number,
  filterValue: FilterItem['value'],
): boolean => {
  if (operator === FilterOperator.Equal) {
    return dataValue === filterValue;
  } else if (operator === FilterOperator.NotEqual) {
    return dataValue !== filterValue;
  } else if (
    operator === FilterOperator.IsAnyOf &&
    Array.isArray(filterValue)
  ) {
    // Check the type of the first element to decide whether to cast to string[] or number[]
    if (typeof dataValue === 'string') {
      return (filterValue as string[])
        .map((s) => s.toLowerCase())
        .includes(dataValue.toLowerCase()); // ignore casing when string matching
    } else if (typeof dataValue === 'number') {
      return (filterValue as number[]).includes(dataValue);
    }
  }

  return true;
};

const daysToBigInt = (days: number): bigint => {
  const now = BigInt(Date.now());
  const daysInt = BigInt(Math.round(days)); // Ensure days is an big int
  return now + daysInt * (1000n * 60n * 60n * 24n); // milliseconds in a day
};

export const getTransactionSentiment = (
  row: RawOptionFeedData,
): TransactionSentiment => {
  const isBullish =
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.BUY &&
      (row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.AA ||
        row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.A) &&
      row[OptionsFeedColumnKey.IsPut] === false) ||
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.SELL &&
      (row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.BB ||
        row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.B) &&
      row[OptionsFeedColumnKey.IsPut] === true);

  const isBearish =
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.BUY &&
      (row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.AA ||
        row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.A) &&
      row[OptionsFeedColumnKey.IsPut] === true) ||
    (row[OptionsFeedColumnKey.Aggressor] === Aggressor.SELL &&
      (row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.BB ||
        row[OptionsFeedColumnKey.TradeSide] === OptionTradeSide.B) &&
      row[OptionsFeedColumnKey.IsPut] === false);

  return isBullish ? 'bullish' : isBearish ? 'bearish' : 'neutral';
};

// Utility to get filter value or return default
export const getFilterValue = <T>(
  filters: Filter[],
  filterId: OF_DEFAULT_FILTER_ID,
  defaultValue: T,
): T => {
  const filter = filters.find((f) => f.id === filterId) as FilterItem;
  return (filter?.value as T) ?? defaultValue;
};

export const getFilterConjunction = <Conjunction>(
  filters: Filter[],
  cId: OF_FILTER_CONJUNCTION_ID,
  defaultValue: Conjunction,
): Conjunction => {
  const filter = filters.find((f) => f.id === cId) as Conjunction;
  return filter ?? defaultValue;
};

export const isConjunction = (filter: Filter): filter is Conjunction =>
  'op' in filter;

export const optionsFeedComparator = <
  T extends RawOptionFeedData | RawOptionFeedContract,
>(
  a: T,
  b: T,
  sortItem: GridSortItem,
) => {
  const { field, sort } = sortItem;
  const valueA = a[field as keyof T];
  const valueB = b[field as keyof T];

  const dir = sort === 'asc' ? 1 : -1;

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return (
      gridStringOrNumberComparator(valueA, valueB, {} as any, {} as any) * dir
    );
  } else if (
    (typeof valueA === 'number' || typeof valueA === 'bigint') &&
    (typeof valueB === 'number' || typeof valueB === 'bigint')
  ) {
    return gridNumberComparator(valueA, valueB, {} as any, {} as any) * dir;
  } else if (valueA instanceof Date && valueB instanceof Date) {
    return gridDateComparator(valueA, valueB, {} as any, {} as any) * dir;
  }
  return 0;
};

export const multiSortComparator = <
  T extends RawOptionFeedData | RawOptionFeedContract,
>(
  a: T,
  b: T,
  sortItems: GridSortItem[],
): number => {
  for (const sortItem of sortItems) {
    const comparison = optionsFeedComparator(a, b, sortItem);
    if (comparison !== 0) {
      return comparison;
    }
  }
  return 0;
};

const DEFAULT_TIME_SORT: GridSortItem = {
  field: OptionsFeedColumnKey.Time,
  sort: 'desc', // Most recent first
};

export const getSortedOptionsFeedData = <
  T extends RawOptionFeedData | RawOptionFeedContract,
>(
  data: T[],
  sortModel: GridSortModel,
): T[] => {
  // Create a complete sort model that includes the time sort
  const completeSortModel = [...sortModel];

  // Only add time sort if it's not already included
  const hasTimeSort = completeSortModel.some(
    (item) => item.field === OptionsFeedColumnKey.Time,
  );

  if (!hasTimeSort) {
    completeSortModel.push(DEFAULT_TIME_SORT);
  }

  return [...data].sort((a, b) => multiSortComparator(a, b, completeSortModel));
};

export const makeTnsFlowSummary = (rawFlowSumData: any): TnsFlowSummary => {
  // Initialize an empty object to build TnsFlowSummary
  const flowSum: Partial<TnsFlowSummary> = {};

  // Iterate over each category to populate flowSum
  FLOW_SUM_CATEGORIES.forEach((category) => {
    const data = rawFlowSumData[category];

    if (data) {
      const { put, call } = data;

      // Assign the transformed PCFlowSum with 'total'
      flowSum[category] = {
        put: put,
        call: call,
        total: (put ?? 0) + (call ?? 0),
      };
    }
  });

  // Type assertion since we've ensured all categories are present
  return flowSum as TnsFlowSummary;
};

// function to handle conversion of value types backend expects
export const getFiltersForPayload = (filters: Filter[]) =>
  filters.map((f: Filter) => {
    if (!isConjunction(f)) {
      if (f.field === OptionsFeedColumnKey.Expiry) {
        return {
          ...f,
          value: Math.round(daysToTimestamp(f.value as number)),
        };
      }
      // add more cases as necessary
    }

    return f;
  });

const daysToTimestamp = (days: number): number => {
  const now = Date.now();
  return now + days * ONE_DAY_MS;
};

/**
 * Deduplicates two arrays of rows based on the 'id' field.
 * Prioritizes rows from liveRows over histRows.
 *
 * @param {(RawOptionFeedData | RawOptionFeedContract)[]} liveRows - Rows from WebSocket (live data).
 * @param {(RawOptionFeedData | RawOptionFeedContract)[]} histRows - Rows from historical data.
 * @returns {(RawOptionFeedData | RawOptionFeedContract)[]} - Deduplicated array of rows.
 */
export const dedupeTnsRows = <
  T extends RawOptionFeedData | RawOptionFeedContract,
>(
  liveRows: T[],
  histRows: T[],
): T[] => {
  const seenIds = new Set<string>();
  const deduped: T[] = [];

  // Add live rows first
  for (const row of liveRows) {
    if (row.id && !seenIds.has(row.id)) {
      seenIds.add(row.id);
      deduped.push(row);
    }
  }

  // Add historical rows, skipping duplicates
  for (const row of histRows) {
    if (row.id && !seenIds.has(row.id)) {
      seenIds.add(row.id);
      deduped.push(row);
    }
  }

  return deduped;
};

export const fitsTnsSortedWindow = (
  row: RawOptionFeedData,
  sortedDataWindow: TnsSortedDataWindow,
): boolean => {
  const { sortModel, firstRow, lastRow } = sortedDataWindow;

  // If no sort model, we can't determine if it fits
  if (sortModel.length === 0 || !firstRow || !lastRow) {
    return false;
  }

  // Create complete sort model with time sort
  const completeSortModel = [...sortModel];
  if (
    !completeSortModel.some((item) => item.field === OptionsFeedColumnKey.Time)
  ) {
    completeSortModel.push(DEFAULT_TIME_SORT);
  }

  // Compare with first and last rows using all sort criteria
  const firstRowComparison = multiSortComparator(
    row,
    firstRow,
    completeSortModel,
  );
  const lastRowComparison = multiSortComparator(
    row,
    lastRow,
    completeSortModel,
  );

  // For ascending sort:
  // - row should be greater than or equal to firstRow (firstRowComparison >= 0)
  // - row should be less than or equal to lastRow (lastRowComparison <= 0)
  // For descending sort:
  // - row should be less than or equal to firstRow (firstRowComparison <= 0)
  // - row should be greater than or equal to lastRow (lastRowComparison >= 0)
  return sortModel[0].sort === 'asc'
    ? firstRowComparison >= 0 && lastRowComparison <= 0
    : firstRowComparison <= 0 && lastRowComparison >= 0;
};

export const getOptionFeedContract = (data: RawOptionFeedContract) => ({
  ...data,
  id: uuidv4(),
});
