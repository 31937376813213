import { EquityGraph } from '../../components';
import { EquityhubStockScanner } from '../../components/equityhub/EquityhubTable/EquityhubStockScanner';
import { Equity, ProductType, SearchHandlerFunction } from '../../types';
import { WatchlistAndTrending } from '../../components/watchlist/WatchlistAndTrending';
import { ExpandableDashboardLayout } from 'layouts';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useCleanup from '../../hooks/useCleanup';
import {
  activeSearchState,
  ehActiveScannerState,
  equitiesFetchLoadingState,
  equitySymbolsState,
  freeSymbolState,
  pricesState,
  productAccessState,
  searchHandlerState,
  searchSuggestionsData,
  selectedEquitySymbolState,
  unpricedEquitiesState,
} from '../../states';
import usePrices from 'hooks/equityhub/usePrices';
import useEquities from 'hooks/equityhub/useEquities';
import useSetSym from 'hooks/hiro/useSetSym';

export const Equityhub = () => {
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const setSelectedSym = useSetRecoilState(selectedEquitySymbolState);
  const setActiveSearch = useSetRecoilState(activeSearchState);
  const setPrices = useSetRecoilState(pricesState);
  const { getPrices } = usePrices();
  const products = useRecoilValue(productAccessState);
  const hasEquityhubAccess = useMemo(
    () => products.includes(ProductType.EQUITYHUB),
    [products],
  );
  const setActiveScanner = useSetRecoilState(ehActiveScannerState);
  const equityInstruments = useRecoilValue(equitySymbolsState);
  const liveEhSymbol = useRecoilValue(freeSymbolState);

  const { setSym, getSymRaw, sym } = useSetSym();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);

  useCleanup();

  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (value: string): void => {
      const sym = value.toUpperCase();
      const ehSym = unpricedEquities.has(sym) ? sym : undefined;
      setSelectedSym(ehSym);
      setSym(sym, ProductType.EQUITYHUB);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [unpricedEquities, setSelectedSym, setSym, setSuggestionsHandler]);

  useEffect(() => {
    // search suggestions data
    setSuggestionsData(equityInstruments);
  }, [equityInstruments, setSuggestionsData]);

  useEffect(() => {
    if (!hasEquityhubAccess) {
      const newSym = setSym(liveEhSymbol, ProductType.EQUITYHUB);
      setActiveScanner(undefined);
      setSelectedSym(newSym);
      setActiveSearch(newSym);
      return;
    }

    let newSym = getSymRaw();
    // Check if sym is not null and is available, otherwise use default
    if (
      !equitiesLoading &&
      unpricedEquities.size > 0 &&
      !unpricedEquities.has(sym)
    ) {
      newSym = null; // setSym will automatically set to default
    }

    const finalSym = setSym(newSym, ProductType.EQUITYHUB);
    setSelectedSym(finalSym);
    setActiveSearch(finalSym);
  }, [
    equitiesLoading,
    unpricedEquities,
    sym,
    setSelectedSym,
    setActiveSearch,
    hasEquityhubAccess,
    liveEhSymbol,
    setSym,
  ]);

  useEffect(() => {
    async function fetchPrices() {
      const payload = await getPrices([]);
      setPrices(payload);
    }
    fetchPrices();
  }, []);

  return (
    <ExpandableDashboardLayout
      mainContentComponent={<EquityGraph />}
      secondarySidebarComponent={
        <WatchlistAndTrending type={ProductType.EQUITYHUB} />
      }
      bottomDrawerComponent={<EquityhubStockScanner />}
      mainContentDefaultHeight={0.57}
    />
  );
};
