import {
  alpha,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components/shared';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DEFAULT_PANEL_STYLES, OptionsFeedFilterTab } from 'config/optionsFeed';
import FilterPanel from './FilterPanel';
import { Filter, FilterConfig, FilterPanelProps } from 'types/optionsFeed';
import { currentToastState, isMobileState } from 'states';
import FiltersList from './FiltersList';
import FilterSectionLabel from './FilterSectionLabel';
import { HideSupportStyle } from 'components/shared/HideSupportStyle';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import { memo, useState } from 'react';

interface FiltersContainerProps {
  activeTabState: RecoilState<OptionsFeedFilterTab>;
  newFilterItemsState: RecoilState<Filter[]>;
  savedFiltersState: RecoilState<FilterConfig[]>;
  activeCustomFilterState: RecoilState<FilterConfig | undefined>;
  filterPanelProps: FilterPanelProps;
}

const FiltersContainer = memo(
  ({
    activeTabState,
    newFilterItemsState,
    activeCustomFilterState,
    savedFiltersState,
    filterPanelProps,
  }: FiltersContainerProps) => {
    const theme = useTheme();
    const setToast = useSetRecoilState(currentToastState);
    const isMobile = useRecoilValue(isMobileState);
    const [newFilterName, setNewFilterName] = useState<string>('');
    const [saveNewModalOpen, setSaveNewModalOpen] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useRecoilState(activeTabState);
    const [newFilters, setNewFilters] = useRecoilState(newFilterItemsState);
    const [activeCustomFilter, setActiveCustomFilter] = useRecoilState(
      activeCustomFilterState,
    );
    const [filterPanelOpen, setFilterPanelOpen] = useRecoilState(
      filterPanelProps.openState,
    );
    const [savedFilters, setSavedFilters] = useRecoilState(savedFiltersState);

    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [editLoading, setEditLoading] = useState<boolean>(false);
    const [createLoading, setCreateLoading] = useState<boolean>(false);

    const { editSavedFilter, deleteFilter, createNewSavedFilter } =
      useTnsFilters();

    const handleCloseFilterModal = () => setFilterPanelOpen(false);

    const onChangeNewFilters = (newFilters: Filter[]) => {
      setNewFilters(newFilters);
    };

    const openSaveNewModal = () => setSaveNewModalOpen(true);
    const closeSaveNewModal = () => setSaveNewModalOpen(false);

    const onResetNewFilters = () => setNewFilters([]);

    const currentFilters = activeCustomFilter?.value ?? newFilters;

    const onActiveFilterChange = (filter: FilterConfig | undefined) =>
      setActiveCustomFilter(
        activeCustomFilter?.id === filter?.id ? undefined : filter,
      );

    const onCreateNewFilter = async (): Promise<void> => {
      try {
        setCreateLoading(true);
        const newSavedFilter = await createNewSavedFilter(
          newFilterName.trim(),
          newFilters,
          filterPanelProps.noSym ?? false,
        );
        setSavedFilters((prev) => [...prev, newSavedFilter]);
        closeSaveNewModal();
        setToast({
          message: `Successfully saved your new filter ${newFilterName.trim()}`,
          type: 'success',
          duration: 5000,
        });
      } catch (err: any) {
        setToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      } finally {
        setCreateLoading(false);
      }
    };

    const onEditSavedFilter = async (fConfig: FilterConfig): Promise<void> => {
      try {
        setEditLoading(true);
        const savedFilter = await editSavedFilter(fConfig);
        if (activeCustomFilter?.id === savedFilter.id) {
          setActiveCustomFilter(savedFilter);
        }
        setSavedFilters((prev) =>
          prev.map((f) => (f.id === savedFilter.id ? savedFilter : f)),
        );
        setToast({
          message: `Successfully updated filter ${fConfig.name}`,
          type: 'success',
          duration: 5000,
        });
      } catch (err: any) {
        setToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      } finally {
        setEditLoading(false);
      }
    };

    const onDeleteSavedFilter = async (
      filterId: Filter['id'],
    ): Promise<void> => {
      try {
        setDeleteLoading(true);
        await deleteFilter(filterId);
        if (activeCustomFilter?.id === filterId) {
          setActiveCustomFilter(undefined);
        }
        setSavedFilters((prev) => prev.filter((f) => f.id !== filterId));
        setToast({
          message: `Successfully deleted`,
          type: 'success',
          duration: 5000,
        });
      } catch (err: any) {
        setToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      } finally {
        setDeleteLoading(false);
      }
    };

    const renderSavedFilters = () => {
      return (
        <Stack gap={2}>
          <FilterSectionLabel title="MY FILTERS" />
          <FiltersList
            selectedFilterId={activeCustomFilter?.id}
            setSelectedFilter={onActiveFilterChange}
            filters={savedFilters}
            onEditSave={onEditSavedFilter}
            onDelete={onDeleteSavedFilter}
            editLoading={editLoading}
            deleteLoading={deleteLoading}
          />
          <FilterSectionLabel title="SPOTGAMMA FILTERS" />
          <FiltersList
            selectedFilterId={activeCustomFilter?.id}
            setSelectedFilter={onActiveFilterChange}
            filters={[]}
          />
        </Stack>
      );
    };

    return (
      <>
        {filterPanelProps.panelView === 'modal' || isMobile ? (
          <>
            <Dialog
              open={filterPanelOpen}
              onClose={handleCloseFilterModal}
              maxWidth="lg"
              fullScreen={isMobile}
            >
              <DialogTitle>
                <Stack gap={0.5}>
                  <TabContext value={activeTab}>
                    <Tabs
                      options={
                        new Map(
                          Object.values(OptionsFeedFilterTab).map((t) => [
                            t,
                            t,
                          ]),
                        )
                      }
                      onChange={(_evt, newTab: OptionsFeedFilterTab) =>
                        setActiveTab(newTab)
                      }
                      isFullWidth
                      controlProps={{
                        options: [
                          <IconButton
                            onClick={handleCloseFilterModal}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            <CloseRoundedIcon />
                          </IconButton>,
                        ],
                      }}
                      tabButtonSx={{ minHeight: 40 }}
                      tabListSx={{ minHeight: 40 }}
                    />
                  </TabContext>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <TabContext value={activeTab}>
                  <TabPanel
                    value={OptionsFeedFilterTab.NewFilter}
                    sx={{ minWidth: 300 }}
                  >
                    <FilterPanel
                      filters={currentFilters}
                      onChangeFilters={onChangeNewFilters}
                      noSym={filterPanelProps.noSym}
                      sx={{
                        paddingTop: '12px',
                        paddingBottom: '36px',
                      }}
                    />
                  </TabPanel>
                  <TabPanel
                    value={OptionsFeedFilterTab.SavedFilters}
                    sx={{ minWidth: 300 }}
                  >
                    {renderSavedFilters()}
                  </TabPanel>
                </TabContext>
              </DialogContent>
              {activeTab === OptionsFeedFilterTab.NewFilter && (
                <DialogActions sx={{ paddingX: '24px' }}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      width: '50%',
                      borderWidth: 2,
                      fontSize: 14,
                      ':hover': {
                        borderWidth: 2,
                      },
                    }}
                    onClick={onResetNewFilters}
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      fontSize: 14,
                      width: '50%',
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.button.default,
                      boxShadow: 'none',
                      ':hover': {
                        backgroundColor: theme.palette.button.hover,
                      },
                    }}
                    onClick={openSaveNewModal}
                  >
                    Save Filter
                  </Button>
                </DialogActions>
              )}
            </Dialog>

            {filterPanelOpen && <HideSupportStyle />}
          </>
        ) : (
          <Collapse
            in={filterPanelOpen}
            orientation="horizontal"
            unmountOnExit
            style={{ minWidth: 'inherit' }}
          >
            <Stack
              sx={{
                ...DEFAULT_PANEL_STYLES,
              }}
            >
              <TabContext value={activeTab}>
                <Tabs
                  options={
                    new Map(
                      Object.values(OptionsFeedFilterTab).map((t) => [t, t]),
                    )
                  }
                  onChange={(_evt, newTab: OptionsFeedFilterTab) =>
                    setActiveTab(newTab)
                  }
                  isFullWidth
                  tabButtonSx={{ minHeight: 40 }}
                  tabListSx={{ minHeight: 40 }}
                />
                <TabPanel
                  value={OptionsFeedFilterTab.NewFilter}
                  sx={{ padding: 0 }}
                >
                  <Stack
                    sx={{
                      height: '100%',
                    }}
                  >
                    <FilterPanel
                      filters={currentFilters}
                      onChangeFilters={onChangeNewFilters}
                      noSym={filterPanelProps.noSym}
                      sx={{
                        overflowY: 'auto',
                        flex: 1,
                        paddingBottom: '24px',
                        paddingX: '12px',
                        overflowX: 'hidden',
                      }}
                    />
                    {/* Sticky Buttons */}
                    <Stack
                      sx={{
                        padding: '16px 0',
                        height: 110,
                        position: 'sticky',
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={4}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            textTransform: 'none',
                            width: '50%',
                            borderWidth: 2,
                            fontSize: 14,
                            ':hover': {
                              borderWidth: 2,
                            },
                          }}
                          onClick={onResetNewFilters}
                        >
                          Reset
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            textTransform: 'none',
                            fontSize: 14,
                            width: '50%',
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.button.default,
                            boxShadow: 'none',
                            ':hover': {
                              backgroundColor: theme.palette.button.hover,
                            },
                          }}
                          onClick={openSaveNewModal}
                        >
                          Save Filter
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </TabPanel>
                <TabPanel
                  value={OptionsFeedFilterTab.SavedFilters}
                  sx={{ padding: 0 }}
                >
                  {renderSavedFilters()}
                </TabPanel>
              </TabContext>
            </Stack>
          </Collapse>
        )}

        <Dialog
          sx={{ '& .MuiDialog-paper': { maxHeight: 435 } }}
          maxWidth="md"
          open={saveNewModalOpen}
          fullScreen={isMobile}
          onClose={closeSaveNewModal}
        >
          <DialogTitle>Name this filter</DialogTitle>
          <DialogContent>
            <Stack py={2}>
              <TextField
                required
                fullWidth
                autoFocus
                id="new-filter-name"
                value={newFilterName}
                onChange={(e) => setNewFilterName(e.target.value as string)}
                placeholder="E.g. My Filter"
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                sx={{
                  width: '100%',
                  minWidth: 280,
                  fontSize: 14,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.sgGreen, 0.85),
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.sgGreen,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.sgGreen,
                  },
                  '& input': {
                    height: 32,
                    padding: '6px 12px',
                    // Autofill styles
                    '&:-webkit-autofill': {
                      backgroundColor: 'transparent !important',
                      boxShadow: `0 0 0 1000px ${alpha(
                        theme.palette.background.default,
                        1,
                      )} inset !important`,
                      WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                    },
                  },
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ paddingX: '24px' }}>
            <Button
              autoFocus
              size="small"
              sx={{
                textTransform: 'none',
                borderWidth: 2,
                fontSize: 14,
                minWidth: 100,
                ':hover': {
                  borderWidth: 2,
                },
              }}
              onClick={closeSaveNewModal}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                textTransform: 'none',
                minWidth: 100,
                fontSize: 14,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.button.default,
                boxShadow: 'none',
                ':hover': {
                  backgroundColor: theme.palette.button.hover,
                },
              }}
              onClick={onCreateNewFilter}
              disabled={newFilterName.trim() === ''}
            >
              {createLoading ? <CircularProgress /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
        {isMobile && saveNewModalOpen && <HideSupportStyle />}
      </>
    );
  },
);

FiltersContainer.displayName = 'FiltersContainer';

export default FiltersContainer;
