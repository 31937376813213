import './styles.css';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { createGlobalStyle } from 'styled-components';
import {
  AdminPage,
  AlertsPage,
  EquityHubPage,
  FoundersNotesPage,
  HiroPage,
  HomePage,
  ImpliedVolPage,
  IndicesPage,
  OpenInterestPage,
  PreferencesPage,
  ResourcesPage,
} from './pages';
import {
  appRefreshCounterState,
  autoRefreshState,
  bbgMarketSessionState,
  endQueryDateState,
  isMobileState,
  startQueryDateState,
  uncachedUserDetailsState,
  userDashAccessState,
  userDetailsState,
  workerState,
} from './states';
import {
  connectToBbgMarketSession,
  getCachedToken,
  getCurrentDate,
  getQueryDate,
  isAdmin,
  isBBEnvAvailable,
  isBloomberg,
  isMarketOpenOnDate,
  isZerohedge,
  preMarketOpen,
  setToken,
  stockMarketOpen,
  isValidTraceTimeframe,
} from './util';
import { ProductType, hasDashAccessToProduct } from 'types';
import { IntegrationsPage } from './pages/IntegrationsPage';
import PollingWorker from 'PollingWorker';
import { HIRO_CHART_UPDATE_DELAY, PRODUCT_LINKS } from './config';
import dayjs from 'dayjs';
import { ZeroHedge } from 'pages/components/ZeroHedge';
import { DiscordSignup, ErrorScreen } from './components';
import { ErrorBoundary } from 'react-error-boundary';
import { Earnings } from 'pages/components/Earnings';
import useAuth from 'hooks/auth/useAuth';
import useHiroList from 'hooks/hiro/useHiroList';
import { ZeroHedgeHiro } from './pages/components';
import useLog from './hooks/useLog';
import { Layout } from './layouts';
import poll from 'util/poll';
import useUserDetails from './hooks/user/useUserDetails';
import { LoginPage } from './pages/LoginPage';
import { StockScannerPage } from 'pages/StockScannerPage';
import { BloombergListener } from './components/bloomberg/BloombergListener';
import { ForceAuthRoute } from './components/core/ForceAuthRoute';
import { SGHtmlPopup } from './components/shared/SGHtmlPopup';
import useRefresh from './hooks/useRefresh';
import { OptionsFeedPage } from 'pages/OptionsFeedPage';
import { IntradayGammaPage } from './pages/IntradayGammaPage';
import { AdminNotificationsPage } from 'pages/AdminNotificationsPage';
import usePollUpdate from './hooks/usePollUpdate';
import { AppMetadata } from './AppMetadata';

const REFRESH_INTERVAL = 30 * 60 * 1_000; // 30 min

export const App = ({ worker }: { worker: PollingWorker }) => {
  const setWorkerState = useSetRecoilState(workerState);
  const isMobile = useRecoilValue(isMobileState);
  const [autoRefreshTimer, setAutoRefreshTimer] =
    useRecoilState(autoRefreshState);
  const [endDate, setEndDate] = useRecoilState(endQueryDateState);
  const setStartDate = useSetRecoilState(startQueryDateState);
  const [localToken, setLocalToken] = useState<string | null>(getCachedToken());
  const uncachedUserDetails = useRecoilValue(uncachedUserDetailsState);
  const { initUserDetails } = useUserDetails();
  const userDetails = useRecoilValue(userDetailsState);
  const { validateCachedToken } = useAuth();
  const appRefreshCounter = useRecoilValue(appRefreshCounterState);
  const setBbgMarketSession = useSetRecoilState(bbgMarketSessionState);
  const userDashAccess = useRecoilValue(userDashAccessState);

  const theme = useTheme();
  const location = useLocation();
  const { triggerRefresh } = useRefresh();

  const { logError } = useLog('App');
  usePollUpdate();

  const handleNewToken = useCallback(
    async ({ json, status }: { json: any; status: number }) => {
      if (status < 300) {
        setToken(json.sgToken);
        setLocalToken(json.sgToken);
      } else if (status === 403) {
        // Only clear the token if it's due to a 403 error.  An internal server
        // error (500+) should leave the cached token alone and wait for another
        // refresh attempt.
        setToken(null);
        setLocalToken(null);
        initUserDetails(undefined); // Sign the user out and navigate to the login
      }
    },
    [setLocalToken, initUserDetails],
  );

  useEffect(() => {
    if (!isBloomberg() && localToken != null) {
      return poll(worker, {
        interval: REFRESH_INTERVAL,
        onResponse: handleNewToken,
        url: 'v1/me/refresh',
      });
    }
    // handleNewToken is safe to keep out of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worker, localToken]);

  useEffect(() => {
    if (isBloomberg()) {
      return;
    }

    // If we detect that we're relying on cached userDetails and credentials,
    // ensure our token is still valid, otherwise clear it all out
    if (uncachedUserDetails == null && userDetails != null) {
      validateCachedToken();
    }
  }, [validateCachedToken, userDetails, uncachedUserDetails]);

  useEffect(() => {
    setWorkerState(worker);
  }, [worker, setWorkerState]);

  const scheduleAutoRefreshTimer = () => {
    // schedule for the next premarket or market open, whichever is earliest
    let cutoff =
      dayjs() >= stockMarketOpen() ? preMarketOpen() : stockMarketOpen();
    while (getCurrentDate() > cutoff || !isMarketOpenOnDate(cutoff)) {
      cutoff = cutoff.add(1, 'day');
    }

    const timeToCutoff = cutoff.diff(getCurrentDate());
    const refreshTimer = setTimeout(() => {
      const targetDate = getQueryDate();
      if (!endDate.isSame(targetDate, 'day')) {
        setStartDate(targetDate);
        setEndDate(targetDate);
      }
      setAutoRefreshTimer(undefined);
    }, timeToCutoff + HIRO_CHART_UPDATE_DELAY);
    setAutoRefreshTimer(refreshTimer);
  };

  useEffect(() => {
    if (autoRefreshTimer == null && userDetails != null) {
      scheduleAutoRefreshTimer();
    }
  }, [autoRefreshTimer, userDetails]);

  useEffect(() => {
    if (!isBBEnvAvailable()) {
      return;
    }

    let session: any = null;
    async function connectToSession() {
      session = await connectToBbgMarketSession();
      setBbgMarketSession(session);
      console.log('created session', session);
    }

    connectToSession();

    return () => {
      if (session) {
        session.destroy();
      }
    };
  }, []);

  const authed = (element: ReactNode) => (
    <ForceAuthRoute>{element}</ForceAuthRoute>
  );

  const renderRoutes = () => {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage location={location} />} />
        {/* Routes that do not force login */}
        <Route path="/html/:category" element={<SGHtmlPopup />} />
        <Route path="/zerohedge" element={<ZeroHedge />} />
        <Route path="/zerohedge-hiro" element={<ZeroHedgeHiro />} />
        <Route path="/earnings" element={<Earnings />} />
        <Route path="/" element={<Layout />}>
          <Route
            path="/"
            element={<Navigate to={isBloomberg() ? `/hiro` : '/home'} />}
          />
          <Route
            path="/foundersNotes/preview/:previewKey"
            element={<FoundersNotesPage />}
            key="/foundersNotes/preview"
          />
          {/* Routes that do force login */}
          <Route path="/hiro" element={authed(<HiroPage />)} />
          <Route path="/resources" element={authed(<ResourcesPage />)} />
          <Route
            path="/resources/discord"
            element={authed(<DiscordSignup />)}
          />
          <Route path="/equityhub" element={authed(<EquityHubPage />)} />
          <Route path="/scanners" element={authed(<StockScannerPage />)} />
          <Route path="/home" element={authed(<HomePage />)} key="/home" />
          <Route path="/allMyAlerts" element={authed(<AlertsPage />)} />
          <Route path="/oi" element={<OpenInterestPage />} />
          <Route
            path="/foundersNotes"
            element={authed(<FoundersNotesPage />)}
            key="/foundersNotes"
          />
          <Route
            path="/foundersNotes/:id"
            element={authed(<FoundersNotesPage />)}
            key="/foundersNotes/:id"
          />
          {hasDashAccessToProduct(userDashAccess, ProductType.OPTIONS_FEED) && (
            <Route
              path="/optionsFeed"
              element={authed(<OptionsFeedPage />)}
              key="/optionsFeed"
            />
          )}
          <Route
            path="/indices"
            element={authed(<IndicesPage />)}
            key="/indices"
          />
          <Route
            path={PRODUCT_LINKS[ProductType.INTEGRATIONS].link}
            element={authed(<IntegrationsPage />)}
            key={PRODUCT_LINKS[ProductType.INTEGRATIONS].link}
          />
          <Route
            path={PRODUCT_LINKS[ProductType.IMPLIED_VOL].link}
            element={authed(<ImpliedVolPage />)}
            key={PRODUCT_LINKS[ProductType.IMPLIED_VOL].link}
          />
          {isValidTraceTimeframe() ? (
            <Route
              path={PRODUCT_LINKS[ProductType.TRACE].link}
              element={<IntradayGammaPage />}
              key={PRODUCT_LINKS[ProductType.TRACE].link}
            />
          ) : (
            hasDashAccessToProduct(userDashAccess, ProductType.TRACE) && (
              <Route
                path={PRODUCT_LINKS[ProductType.TRACE].link}
                element={authed(<IntradayGammaPage />)}
                key={PRODUCT_LINKS[ProductType.TRACE].link}
              />
            )
          )}

          {isAdmin(userDetails) && (
            <Route path="/admin" element={authed(<AdminPage />)} key="/admin" />
          )}
          {isAdmin(userDetails) && (
            <Route
              path="/admin/notifications"
              element={authed(<AdminNotificationsPage />)}
              key="/admin/notifications"
            />
          )}
          {getCachedToken() != null && (
            <Route path="/preferences" element={authed(<PreferencesPage />)} />
          )}
        </Route>

        {/*this catch-all needs to be the last route*/}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  try {
    const GlobalStyles = createGlobalStyle`
      html {
        background: ${theme.palette.background.default}
      }
      .MuiCalendarPicker-root button.MuiPickersArrowSwitcher-button {
        color: ${theme.palette.text.primary};
      }
    `;

    return (
      <ErrorBoundary
        onError={(error) =>
          logError(error, 'ErrorBoundary', { stack: error.stack })
        }
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorScreen
            error={error}
            tryAgainCallback={() => {
              resetErrorBoundary();
              triggerRefresh();
            }}
          />
        )}
      >
        <div
          style={{
            height: isMobile ? undefined : '100vh',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          key={`app-container-${appRefreshCounter}`}
        >
          <GlobalStyles />
          <AppMetadata
            localToken={localToken}
            location={location}
            worker={worker}
          />
          {renderRoutes()}
          {isBBEnvAvailable() && <BloombergListener />}
        </div>
      </ErrorBoundary>
    );
  } catch (err) {
    // second try/catch in case an error happens before ErrorBoundary is rendered
    return <ErrorScreen error={err as any} />;
  }
};
