import { Box, Stack, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  GridColDef,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from 'states';
import {
  dayjs,
  formatAsCompactNumber,
  formatAsCurrency,
  formatAsPercentage,
  getDateFormatted,
  isOutsideTradingHours,
  nullsToEndComparator,
  valOrNa,
} from 'util/shared';
import {
  Aggressor,
  AggressorLabels,
  OptionFlag,
  OptionSaleType,
  OptionSaleTypeLabels,
  OptionsFeedColumnKey,
  OptionsFeedContractColumnKey,
  OptionTradeSide,
  OptionTradeSideLabels,
  OptionType,
  TransactionSentiment,
} from 'types/optionsFeed';
import { getTransactionSentiment } from 'util/optionsFeed';
import { SGTooltip } from 'components/core';
import { Earnings } from 'types';
import { blue, lightBlue, red } from '@mui/material/colors';
import { ColorMode } from 'theme';

interface UseOptionsFeedColumnsProps {
  disabledColumnFilters?: OptionsFeedColumnKey[];
  blurredRowIds?: string[];
  earningsList: Earnings[];
}

export const useOptionsFeedColumns = ({
  disabledColumnFilters,
  blurredRowIds,
  earningsList,
}: UseOptionsFeedColumnsProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 12 : 14,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (_params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: theme.palette.primary.main,
    }),
    [defaultHeaderTitleStyles, theme.palette.equityHubColumns],
  );

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  const underlyingColumn = {
    headerName: 'Symbol',
    field: OptionsFeedColumnKey.Underlying,
    headerClassName: 'grid-header-cell',
    filterable: !disabledColumnFilters?.includes(
      OptionsFeedColumnKey.Underlying,
    ),
    minWidth: 110,
    type: 'string',
    getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
    valueFormatter: (value: string) => `${valOrNa(value)}`,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography>
          {valOrNa(params.value)}{' '}
          {earningsList.find((e) => e.sym === params.value) ? (
            <SGTooltip title="Earnings within 3 weeks from now">
              <sup style={{ fontSize: 8, color: red[600] }}>E</sup>
            </SGTooltip>
          ) : (
            ''
          )}
        </Typography>
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography
        sx={{
          ...getColHeaderStyles(params),
        }}
      >
        Symbol
      </Typography>
    ),
  };

  const cpColumn = {
    headerName: 'C/P',
    field: OptionsFeedColumnKey.IsPut,
    filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.IsPut),
    headerClassName: 'grid-header-cell',
    minWidth: 70,
    type: 'string',
    getSortComparator: nullsToEndComparator(gridStringOrNumberComparator),
    valueFormatter: (value: boolean) =>
      `${valOrNa(value === true ? 'PUT' : 'CALL')}`,
    renderCell: (params: GridRenderCellParams) => {
      const sentiment: TransactionSentiment = getTransactionSentiment(
        params.row,
      );

      return (
        <Typography
          sx={{
            ...getBlurredStyles(params),
            color:
              sentiment === 'bullish'
                ? serverPositiveTrendColor
                : sentiment === 'bearish'
                ? serverNegativeTrendColor
                : 'inherit',
            fontWeight: 600,
          }}
        >
          {valOrNa(params.value === true ? 'PUT' : 'CALL')}
        </Typography>
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography
        sx={{
          ...getColHeaderStyles(params),
        }}
      >
        C/P
      </Typography>
    ),
  };

  const strikeColumn = {
    headerName: 'Strike',
    field: OptionsFeedColumnKey.Strike,
    filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Strike),
    headerClassName: 'grid-header-cell',
    minWidth: 100,
    type: 'number',
    getSortComparator: nullsToEndComparator(gridNumberComparator),
    valueFormatter: (value: number) => `${valOrNa(value)}`,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            ...getBlurredStyles(params),
          }}
        >
          {valOrNa(params.value?.toLocaleString())}
        </Typography>
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography
        sx={{
          ...getColHeaderStyles(params),
        }}
      >
        Strike
      </Typography>
    ),
  };

  const columns: GridColDef[] = [
    {
      headerName: 'Underlying',
      field: OptionsFeedColumnKey.Time,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Time),
      minWidth: 140,
      type: 'dateTime',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: bigint) => {
        return value ? dayjs.utc(parseInt(value.toString())).toDate() : null;
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = dayjs(params?.value).utc();

        const tsForUser = date.tz(currentTimezone);

        // Check if the date is same as today
        const formattedTime = tsForUser.isSame(
          dayjs().tz(currentTimezone),
          'day',
        )
          ? tsForUser.format('HH:mm:ss')
          : tsForUser.format('DD/MM, HH:mm:ss'); // used for "past" dates

        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(formattedTime)}{' '}
            {isOutsideTradingHours(date) && (
              <SGTooltip title="Extended Trading Hours">
                <sub style={{ fontSize: 8 }}>ETH</sub>
              </SGTooltip>
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Time
        </Typography>
      ),
    },
    underlyingColumn,
    {
      headerName: 'Side',
      field: OptionsFeedColumnKey.TradeSide,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.TradeSide,
      ),
      minWidth: 90,
      type: 'singleSelect',
      valueOptions: [
        OptionTradeSide.BB,
        OptionTradeSide.B,
        OptionTradeSide.M,
        OptionTradeSide.A,
        OptionTradeSide.AA,
      ],
      getOptionLabel(value: OptionTradeSide) {
        return OptionTradeSideLabels[value];
      },
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: string) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(
              OptionTradeSideLabels[
                params.value as OptionTradeSide
              ]?.toUpperCase(),
            )}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Side
        </Typography>
      ),
    },
    {
      headerName: 'Buy/Sell',
      field: OptionsFeedColumnKey.Aggressor,
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.Aggressor,
      ),
      headerClassName: 'grid-header-cell',
      minWidth: 70,
      type: 'singleSelect',
      valueOptions: [Aggressor.BUY, Aggressor.SELL],
      getOptionLabel(value: Aggressor) {
        return AggressorLabels[value];
      },
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      valueFormatter: (value: Aggressor) => `${valOrNa(value)}`,
      renderCell: (params: GridRenderCellParams) => {
        const sentiment: TransactionSentiment = getTransactionSentiment(
          params.row,
        );
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
              color:
                sentiment === 'bullish'
                  ? serverPositiveTrendColor
                  : sentiment === 'bearish'
                  ? serverNegativeTrendColor
                  : 'inherit',
              fontWeight: 600,
            }}
          >
            {valOrNa(params.value.toString().toUpperCase())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Buy/Sell
        </Typography>
      ),
    },
    cpColumn,
    strikeColumn,
    {
      headerName: 'Expiration',
      field: OptionsFeedColumnKey.Expiry,
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Expiry),
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      type: 'date',
      getSortComparator: nullsToEndComparator(gridDateComparator),
      valueGetter: (value: bigint) => {
        return value ? dayjs.utc(parseInt(value.toString())).toDate() : null;
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = dayjs(params.value).utc();
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(date != null && getDateFormatted(date))}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Expiration
        </Typography>
      ),
    },
    {
      headerName: 'Volume',
      field: OptionsFeedColumnKey.DailyVolCumsum,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.DailyVolCumsum,
      ),
      minWidth: 125,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(formatAsCompactNumber(params.value))}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Volume
        </Typography>
      ),
    },
    {
      headerName: 'OI',
      field: OptionsFeedColumnKey.PrevOi,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.StockPrice,
      ),
      minWidth: 125,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && formatAsCompactNumber(params.value)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          OI
        </Typography>
      ),
    },
    {
      headerName: 'Premium',
      field: OptionsFeedColumnKey.Premium,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.Premium,
      ),
      minWidth: 130,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{
            ...getBlurredStyles(params),
          }}
        >
          {params.value && `$${formatAsCompactNumber(params.value)}`}
        </Typography>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Premium
        </Typography>
      ),
    },
    {
      headerName: 'Size',
      field: OptionsFeedColumnKey.Size,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Size),
      minWidth: 130,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Size
        </Typography>
      ),
    },
    {
      headerName: 'Spot',
      field: OptionsFeedColumnKey.StockPrice,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(
        OptionsFeedColumnKey.StockPrice,
      ),
      minWidth: 125,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && formatAsCurrency(params.value)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Spot
        </Typography>
      ),
    },
    {
      headerName: 'Bid',
      field: OptionsFeedColumnKey.Bid,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Bid),
      minWidth: 100,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Bid
        </Typography>
      ),
    },
    {
      headerName: 'Ask',
      field: OptionsFeedColumnKey.Ask,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Ask),
      minWidth: 100,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Ask
        </Typography>
      ),
    },
    {
      headerName: 'Option Price',
      field: OptionsFeedColumnKey.Price,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Price),
      minWidth: 130,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value?.toLocaleString())}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Option Price
        </Typography>
      ),
    },
    {
      headerName: 'Flags',
      field: OptionsFeedColumnKey.Flags,
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.Flags),
      headerClassName: 'grid-header-cell',
      minWidth: 110,
      sortable: false,
      valueGetter: ({ isBlock, isSpread, isSweep, isCross }: OptionFlag) => {
        const flags: OptionSaleType[] = [];

        if (isBlock) {
          flags.push(OptionSaleType.BLOCK);
        }
        if (isSweep) {
          flags.push(OptionSaleType.SWEEP);
        }
        if (isSpread) {
          flags.push(OptionSaleType.SPREAD);
        }
        if (isCross) {
          flags.push(OptionSaleType.CROSS);
        }

        return flags;
      },
      renderCell: (params: GridRenderCellParams) => {
        return params.value?.length !== 0 ? (
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            {params.value?.map((v: OptionSaleType) => (
              <Box
                sx={{
                  paddingX: '8px',
                  backgroundColor:
                    theme.colorMode === ColorMode.LIGHT
                      ? lightBlue[300]
                      : blue[500],
                  borderRadius: 12,
                  height: 19,
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                    fontSize: 10,
                  }}
                >
                  {valOrNa(OptionSaleTypeLabels[v])}
                </Typography>
              </Box>
            ))}
          </Stack>
        ) : null;
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Flags
        </Typography>
      ),
    },
    {
      headerName: 'IV',
      field: OptionsFeedColumnKey.IVol,
      headerClassName: 'grid-header-cell',
      filterable: !disabledColumnFilters?.includes(OptionsFeedColumnKey.IVol),
      minWidth: 80,
      type: 'number',
      getSortComparator: nullsToEndComparator(gridNumberComparator),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && formatAsPercentage(params.value)}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          IV
        </Typography>
      ),
    },
  ] as GridColDef[];

  const contractColumns: GridColDef[] = [
    underlyingColumn,
    cpColumn,
    strikeColumn,
    {
      headerName: 'Expiration',
      field: OptionsFeedColumnKey.Expiry,
      headerClassName: 'grid-header-cell',
      minWidth: 120,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              whiteSpace: 'normal',
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(params.value != null && getDateFormatted(params.value))}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Expiration
        </Typography>
      ),
    },
    {
      headerName: 'Total Premium',
      field: OptionsFeedContractColumnKey.TotalPremium,
      headerClassName: 'grid-header-cell',
      minWidth: 145,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {params.value && `$${formatAsCompactNumber(params.value)}`}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Total Premium
        </Typography>
      ),
    },
    {
      headerName: 'Total Volume',
      field: OptionsFeedContractColumnKey.TotalVolume,
      headerClassName: 'grid-header-cell',
      minWidth: 145,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(value ? formatAsCompactNumber(value) : '')}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Total Volume
        </Typography>
      ),
    },
    {
      headerName: 'Delta Notional',
      field: OptionsFeedContractColumnKey.DeltaNotional,
      headerClassName: 'grid-header-cell',
      minWidth: 145,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(value ? formatAsCompactNumber(value) : '')}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Delta Notional
        </Typography>
      ),
    },
    {
      headerName: 'Gamma Notional',
      field: OptionsFeedContractColumnKey.GammaNotional,
      headerClassName: 'grid-header-cell',
      minWidth: 145,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(value ? formatAsCompactNumber(value) : '')}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Gamma Notional
        </Typography>
      ),
    },
    {
      headerName: 'Vega Notional',
      field: OptionsFeedContractColumnKey.VegaNotional,
      headerClassName: 'grid-header-cell',
      minWidth: 145,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <Typography
            sx={{
              ...getBlurredStyles(params),
            }}
          >
            {valOrNa(value ? formatAsCompactNumber(value) : '')}
          </Typography>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          sx={{
            ...getColHeaderStyles(params),
          }}
        >
          Vega Notional
        </Typography>
      ),
    },
  ] as GridColDef[];

  return {
    columns,
    contractColumns,
  };
};
