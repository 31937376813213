import {
  tnsContractsColumnsSortModelState,
  tnsFlowLiveState,
  tnsContractsColumnsVisibilityState,
  tnsContractsColumnOrderState,
  tnsContractsColumnSizesState,
} from 'states/optionsFeed';
import OptionsFeedDatagrid from './OptionsFeedDatagrid';
import { RecoilState, useRecoilValue } from 'recoil';
import {
  DataGridPremiumProps,
  GridColDef,
  GridRowScrollEndParams,
  GridSortModel,
} from '@spotgamma/x-data-grid-premium';
import { Filter, RawOptionFeedContract } from 'types/optionsFeed';
import { STREAM_HOST_URL } from 'config';
import { useCallback, useEffect, useState } from 'react';
import poll from 'util/poll';
import useToast from 'hooks/useToast';
import { workerState } from 'states';
import useTnsContracts from 'hooks/optionsFeed/useTnsContracts';
import { OPTIONS_FEED_FETCH_BATCH_SIZE } from 'config/optionsFeed';
import {
  dedupeTnsRows,
  getFiltersForPayload,
  getOptionFeedContract,
  getSortedOptionsFeedData,
} from 'util/optionsFeed';
import { encodeURIJson } from 'util/shared';

interface ContractDataContainerProps {
  columns: GridColDef[];
  filters: Filter[];
  filterPanelOpenState: RecoilState<boolean>;
}

const ContractDataContainer = ({
  columns,
  filters,
  filterPanelOpenState,
}: ContractDataContainerProps) => {
  const worker = useRecoilValue(workerState);
  const { openToast } = useToast();
  const sortModel = useRecoilValue(tnsContractsColumnsSortModelState);
  const [dataOffset, setDataOffset] = useState<number>(0);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [contractData, setContractData] = useState<RawOptionFeedContract[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false); // To prevent multiple concurrent fetches

  const { fetchContracts } = useTnsContracts();

  const handleResponse = useCallback(async (response: any) => {
    if (response?.json != null) {
      setContractData(response.json.map(getOptionFeedContract));
    }
  }, []);

  const fetchData = async (
    filters: Filter[],
    sorting: GridSortModel,
    offset: number = 0,
    size: number = OPTIONS_FEED_FETCH_BATCH_SIZE,
  ): Promise<RawOptionFeedContract[]> => {
    try {
      setLoading(true);

      const res = await fetchContracts(filters, sorting, offset, size);
      setHasMore(res.length >= size);

      return res;
    } catch (err: any) {
      setError(err?.message);
      openToast({
        message: err?.message,
        type: 'error',
        duration: 7000,
      });
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
    return [];
  };

  useEffect(() => {
    const getData = async (filters: Filter[], sorting: GridSortModel) => {
      setIsFetching(true);
      const results = await fetchData(filters, sorting);
      setContractData(results);
    };

    getData(filters, sortModel); // if filters or sorting changes, refetch from scratch
  }, [filters, sortModel]);

  useEffect(() => {
    return poll(worker, {
      url: `sg/tns_contracts?filters=${encodeURIJson(
        getFiltersForPayload(filters),
      )}&sorting=${encodeURIJson(sortModel)}&offset=${dataOffset}`,
      interval: 30_000, // poll every 30s
      host: STREAM_HOST_URL,
      onResponse: handleResponse,
    });
  }, [worker, filters, sortModel, dataOffset, handleResponse]);

  const handleOnRowsScrollEnd = useCallback<
    NonNullable<DataGridPremiumProps['onRowsScrollEnd']>
  >(
    async (_params: GridRowScrollEndParams) => {
      if (isFetching || !hasMore) {
        return; // Prevent multiple fetches or fetching when no more data
      }

      setIsFetching(true);
      const newData = await fetchData(filters, sortModel, dataOffset);
      setContractData((prev) =>
        getSortedOptionsFeedData(dedupeTnsRows(newData, prev), sortModel),
      );
      setDataOffset((prevOffset) => prevOffset + newData.length);
    },
    [filters, sortModel, dataOffset, isFetching, hasMore],
  );

  return (
    <OptionsFeedDatagrid
      rows={contractData}
      columns={columns}
      columnSortModelState={tnsContractsColumnsSortModelState}
      filterPanelOpenState={filterPanelOpenState}
      flowLiveState={tnsFlowLiveState}
      columnVisibilityState={tnsContractsColumnsVisibilityState}
      columnOrderState={tnsContractsColumnOrderState}
      columnSizingState={tnsContractsColumnSizesState}
      isError={error != null}
      isLoading={loading}
      onRowsScrollEnd={handleOnRowsScrollEnd}
    />
  );
};

export default ContractDataContainer;
